
import axios, { AxiosResponse, AxiosError } from "axios";
import { baseURL } from "../services/baseURL.service";

interface Payload {
    phone_number:string,
}

interface ResendOtpResponse {
  status:Number,
  data?:any,
  error?:string
}

export const useResendOtp=()=>{
  const resendOtp = async (payload:Payload): Promise<ResendOtpResponse>=>{
    try {
      const response:AxiosResponse = await axios.post(`${baseURL}/auth/resend-code/`, { phone_number:payload.phone_number });
      return { status:response.status , data: response }
    } catch (error:any) {
      if(axios.isAxiosError(error)){
        const axiosError:AxiosError = error
        return { status: axiosError.response?.status || 500, error:error.message }
      }else{
        return { status: 500, error:'an unknown error occured'}
      }
    }
  }
  return resendOtp
}