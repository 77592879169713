
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";

interface GetAgentDetailsResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useGetAgentDetails = () => {
  const getAgentDetails = async (slug:any) => {
    try {
      const response = await coreClient.get(`${baseURL}/get-agent-application-details/${slug}/`);
      return { status: response.status, data: response.data } as GetAgentDetailsResponse;
    } catch (error) {
      console.error('Error fetching agent details:', error);
      return { status: 500, error: 'An unknown error occurred' } as GetAgentDetailsResponse;
    }
  };

  return getAgentDetails;
};
