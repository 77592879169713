import React from 'react';
import { AboutSection } from './about';
import { SectionsCard } from '../cards/';
import { WorshipSession } from './others';
import { FAQS } from '../faq';
import { Testimonials } from '../testimonials';
// import { MouseImage } from '../test';
import happyTeam from '../../assets/jpg/happy-team.jpg';
import realEstateAgent from '../../assets/jpg/black-businessman.jpg';
import realEstateAgent2 from '../../assets/jpg/man-working-as-real-estate.jpg';
import happyBusinessWomen from '../../assets/jpg/happy-businesswomen.jpg';
import { PromoCodeBanner } from '../marketing';




const Sections = () => {
  return (
    <>
        {/* <PromoCodeBanner /> */}
        <AboutSection />
        <SectionsCard
          image={happyTeam}
          subTitle='Anywhere-Anytime Convenient'
          title='Property Showings'
          content='
          ShowingXchange lets you delegate property showings to licensed, ready and willing  Real Estate Agents in the area. Setting your time free from this highly time consuming activity,  to focus on other higher value or pressing tasks.
          '
        />
        <SectionsCard
          image={realEstateAgent2}
          subTitle='Wide Coverage'
          title='Locations'
          content='ShowingXchange currently operates across all 50 states in the USA. Expanding to International Markets soon.'
        />
        <WorshipSession
          title='Just ShowingXchange It!'
          content='Whether it’s a property showing you can’t attend, an appraisal appointment that’s too far away, a property inspection, or a final walk-through, ShowingXchange has you covered. Whatever the reason, just ShowingXchange it!'
        />
        <SectionsCard
          image={realEstateAgent}
          subTitle='Available Licensed Agents'
          title='Get Paid for your Free Time'
          content='Agents available to fulfill the Showing Requests get an opportunity to get paid for their free time, thus creating a win-win for both the Real Estate Agents on either sides of the Transaction.'
        />
        <Testimonials />
        {/* <FAQS /> */}
        <SectionsCard
          image={happyBusinessWomen}
          subTitle='Clients Win Too'
          title='Happy Clients'
          content='Clients can view their desired properties at their convenience, regardless of your availability. Happy Clients is what we all need!'
          centered={true}
        />
    </>
  )
}

export default Sections