import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useGetAgentDetails } from '../../hooks/get/useGetAgentDetails';
import { AgentProfileCard } from '../../components/cards';

const ProfileDetails = () => {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const { slug } = useParams();
  const getAgentDetails = useGetAgentDetails();

  useEffect(()=>{
    (async function() {
      try {
        setLoadingDetails(true);
        const { status, data } = await getAgentDetails(slug);
        console.log(data, '====')
        if(status===200){
          setAgentDetails(data.data);
        }
      } catch (error) {
        toast.error('an error occured')
      }finally{
        setLoadingDetails(false);
      }
    })()
  }, [slug])

  if(loadingDetails){
    return <h2>loading details</h2>
  }
  return (
    <div className='h-screen'>
      <AgentProfileCard agentDetails={agentDetails}/>
    </div>
  )
}

export default ProfileDetails