
// import "@tremor/react/dist/esm/tremor.css";
import { Toaster } from 'sonner'
import { PageRoutes } from './routes';

function App() {
  return (
    <>
      <Toaster richColors position="top-right"/>
      <PageRoutes />
    </>
  );
}

export default App;
