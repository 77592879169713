import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { SideBar } from "../../components/sidebar";
import tokenService from "../../services/token.service";

const Dashboard = () => {
  const user = tokenService.getUser();
  const navigateTo = useNavigate();
  if (!user) {
    navigateTo("/login");
    return null
  }
  return (
    <div className='h-screen w-full flex fixed'>
      <div className="w-2/12 border border-r-2 hidden md:block">
        <SideBar />
      </div>
      <div className={`md:w-10/12`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
