import axios, { AxiosResponse, AxiosError } from "axios";
import { baseURL } from "../services/baseURL.service";

interface Payload {
    uid?: string,
    token?:string,
    new_password1?:string,
    new_password2?:string
}

interface verifyAdminTokenResponse {
  status:Number,
  data?:any,
  error?:string
}

export const useVerifyAdminToken=()=>{
  const verifyAdminToken = async (payload:Payload): Promise<verifyAdminTokenResponse>=>{
    try {
      const response:AxiosResponse = await axios.post(`${baseURL}/auth/password/reset/confirm/`, payload);
      return { status:response.status , data: response }
    } catch (error:any) {
      // if(axios.isAxiosError(error)){
      //   const axiosError:AxiosError = error
      //   return { status: axiosError.response?.status || 500, error:error.message }
      // }else{
      //   return { status: 500, error:'an unknown error occured'}
      // }
      throw error
    }
  }
  return verifyAdminToken
}