import * as Yup from 'yup';

const REQUIRED_TXT = 'This field is required';
const EMAIL_TXT = 'Must be a valid email';
const PHONE_TXT = 'Must be a valid number';

const PASSWORD_VALIDATION_MESSAGE = 'Password must be 8-20 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character without spaces';

export const emailRegExp = /^[a-zA-Z0-9_%+-]+(?:\.[a-zA-Z0-9_%+-]+)*@(?!.*(?:\d{1,3}\.){3}\d{1,3})[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?!\.web)[a-zA-Z]{2,}$/;
export const phoneRegex = /^\d{1,15}$/



const passwordValidation = Yup.string()
    .min(8, PASSWORD_VALIDATION_MESSAGE)
    .max(20, PASSWORD_VALIDATION_MESSAGE)
    .matches(/[a-z]/, PASSWORD_VALIDATION_MESSAGE)
    .matches(/[A-Z]/, PASSWORD_VALIDATION_MESSAGE)
    .matches(/\d/, PASSWORD_VALIDATION_MESSAGE)
    .matches(/[!@#$%^&*(),.?":{}|<>]/, PASSWORD_VALIDATION_MESSAGE)
    .matches(/^[^\s]+$/, PASSWORD_VALIDATION_MESSAGE)
    .required(REQUIRED_TXT);

const numberOfYearsValidation = Yup.number()
    .typeError('Please enter a valid number')
    .min(1, 'Number must be greater than 0')
    .max(29, 'Number must be less than 30')
    .required(REQUIRED_TXT)

export const SignUpFormSchema = Yup.object({
    name: Yup.string().required(REQUIRED_TXT),
    //email: Yup.string().email(EMAIL_TXT).required(REQUIRED_TXT),
    email: Yup.string().matches(emailRegExp, EMAIL_TXT).required(REQUIRED_TXT),
    password: passwordValidation,
    passwordRepeated: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required(REQUIRED_TXT),
});
export const SignUpFormSchemaCountryCode = Yup.object({
    email: Yup.string().matches(emailRegExp, EMAIL_TXT).required(REQUIRED_TXT),
    phone_number: Yup.string().matches(phoneRegex, PHONE_TXT).required(REQUIRED_TXT),
    password: passwordValidation,
    passwordRepeated: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required(REQUIRED_TXT),
});

export const LoginFormSchema = Yup.object({
    email: Yup.string().matches(emailRegExp, EMAIL_TXT).required(REQUIRED_TXT),
    password: passwordValidation,
});
export const CreateOperationOrBillingFormSchema = Yup.object({
    email: Yup.string().matches(emailRegExp, EMAIL_TXT).required(REQUIRED_TXT),
    phone_number: Yup.string().matches(phoneRegex, PHONE_TXT).required(REQUIRED_TXT),
    password: passwordValidation,
});

export const ValidateEmailForPasswordResetSchema = Yup.object({
    email: Yup.string().matches(emailRegExp, EMAIL_TXT).required(REQUIRED_TXT),
});

export const PasswordChangeFormSchema = Yup.object({
    new_password1: Yup.string().required(REQUIRED_TXT),
    new_password2: Yup.string()
        .oneOf([Yup.ref('new_password1'), null], 'Passwords does not match')
        .required(REQUIRED_TXT),
});


export const defaultFormSchema = Yup.object({
    name: Yup.string().required(REQUIRED_TXT),
    email: Yup.string().matches(emailRegExp, EMAIL_TXT).required(REQUIRED_TXT),
    currentJobTitle: Yup.string().required(REQUIRED_TXT),
    nextJobTitle: Yup.string().required(REQUIRED_TXT),
    yearsOfExperience:numberOfYearsValidation,
    skills: Yup.string().required(REQUIRED_TXT),
    jobDescription: Yup.string().required(REQUIRED_TXT),
});


