import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import happyMan  from '../../../assets/jpg/man-happy.jpg';



const AboutSection = () => {

  const controls = useAnimation();
    const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }

  
  return (
    <div className='md:h-screen flex items-center flex-col-reverse md:flex-row font-primary px-3 md:px-0'>
      <div className='w-full h-full md:w-1/2 flex  items-center justify-center'>
        <img src={happyMan} className='h-96 w-96 object-cover rounded-full shadow-lg border-2 border-customPrimary p-2' alt="user" />
      </div>
      <div className='w-full md:w-1/2 h-1/2 md:h-full flex items-center justify-center px-5 md:px-0 pt-10 md:pt-0'>
          <div className='md:w-10/12 text-darkBlue'>
            <motion.h3 
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ duration: 1.2, delay: 0.2 }}
            className='text-center capitalize font-xbold text-lg md:text-xl leading-[2.6rem] md:leading-[4.8rem]'>
              Our Vision
            </motion.h3>
            <motion.p 
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ duration: 1.2, delay: 0.4 }}
            className='text-center my-10 text-sm'>
            At <b>ShowingXchange</b>, we are on a mission to revolutionize the real estate industry by creating a collaborative and efficient ecosystem where agents can effortlessly connect, share property showing opportunities, and deliver exceptional property viewing experiences for their clients.
            </motion.p>
          </div>
      </div>
    </div>
  )
}

export default AboutSection