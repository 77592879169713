import React from 'react';
import { Progress } from './ProgressBar';



const ProgressBarTimer = ({ timeCounter }: { timeCounter:any }) => {
  return (
    <div className='mt-5'>
        <h2 className='text-xsm font-medium my-3'>verifying email</h2>
        <Progress value={timeCounter || 1} />
    </div>
  )
}

export default ProgressBarTimer