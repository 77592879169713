import React, { useState, useEffect, Suspense } from 'react';
import { Formik, Form } from "formik";
import { toast } from 'sonner';
import { Search } from 'lucide-react';
import { AgentApplicationListCard } from '../../../components/cards';
import { useListAgents } from '../../../hooks/get/useListAgents';
import { useCreateUser } from '../../../hooks/post/useCreateUser';
import { PlaceholderCard } from '../../../components/skeleton';
import { ModalDialog } from '../../../components/modal';
import { TextInput } from '../../../components/forms/inputs';
import { Button, CreateButton } from '../../../components/button';
import { useModalContext } from '../../../contexts/modalContext';
import { CreateOperationOrBillingFormSchema } from '../../../utils/yupSchemas';
import { countriesFlagPhoneExts } from '../../../utils/countriesFlagPhoneExts';
import { PhoneInput } from '../../../components/forms/inputs';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/SelectFilter"
import { ItemSearch } from '../../../components/search';
import SimpleSpinner from '../../../components/loaders/SimpleSpinner';
import { NoData } from '../../../components/placeholders';



const ListBillings = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchCountryQuery, setCountrySearchQuery] = useState<string>('');
    const [billingAgents, setBillingAgents] = useState([]);
    const [loadingBillings, setLoadingBillings] = useState(false);
    const [showCountrySearch, setShowCountrySearch] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState({name: 'United States', flag: '🇺🇸', code: 'US', dial_code: '+1'});
    const [selectedUserType, setSelectedUserType] = useState<string>('')
    const ListBillings = useListAgents();
    const createUser = useCreateUser();
    const { openModal, close, open } = useModalContext();
  
    const userTypes = ['operation', 'billing'];
  
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      setSelectedUserType(value)
    };
  
    const handleSelectCountry = (country:any)=>{
      setSelectedCountry(country)
    }
  
    const handleToggleCountrySearch = ()=>{
      setShowCountrySearch(!showCountrySearch) 
    }
  
  
    const handleCountrySearchQuery = (e:any)=>{
      setCountrySearchQuery(e.target.value);
      }
  
      const handleSearchQuery = (e:any)=>{
          setSearchQuery(e.target.value);
      }
  
      const filterdAgents = billingAgents && billingAgents.filter((agent:any)=>{
          const result = `${agent?.first_name} ${agent?.gender} ${agent?.last_name} ${agent?.agent_country}`;
          return result.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) 
      })
  
      // console.log(agents && agents, 'agents')
      // console.log(filterdAgents && filterdAgents, 'filterdAgents')
  
      const filterdCountries = countriesFlagPhoneExts && countriesFlagPhoneExts.filter((country)=>{
        const result = `${country?.name} ${country?.dial_code}`;
        return result.toLocaleLowerCase().includes(searchCountryQuery.toLocaleLowerCase()) 
    })
  
      useEffect(()=>{
        setTimeout(async()=>{
          try {
            setLoadingBillings(true);
            const { status, data  } = await ListBillings('list-billing-applications');
            if(status===200){
              setBillingAgents(data?.data)
            }
          } catch (error) {
            console.log(error);
          }finally{
            setLoadingBillings(false)
          }
        },2000);
      },[]);

  return (
    <div className='font-primary'>
        {
            billingAgents && billingAgents.length > 0 ? (
                <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
                    <div className='flex items-center justify-between border-b pb-3'>
                    <h3 className='font-bold text-xsm'>billingAgents Applications ({billingAgents && billingAgents.length})</h3>
                    <div>
                        <Select>
                        <SelectTrigger className="md:w-[180px]">
                            <SelectValue placeholder="All" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="light">Approved</SelectItem>
                            <SelectItem value="system">Pending</SelectItem>
                            <SelectItem value="dark">Rejected</SelectItem>
                        </SelectContent>
                        </Select>
                    </div>
                    </div>
                    <ItemSearch
                    searchQuery={searchQuery}
                    handleSearchQuery={handleSearchQuery}
                    />
                    <div className='h-72 my-5 overflow-y-auto'>
                    {
                        loadingBillings ? (
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                            {Array.from({ length: 8}).map((_, i) => (
                            <PlaceholderCard key={i} />
                            ))}
                        </div>
                        ) : (
                        <div className='overflow-y-auto'>
                            <table className="table table-sm">
                            <thead>
                                <tr>
                                <th>Name</th>
                                <th>Job</th>
                                <th>Status</th>
                                <th></th>
                                </tr>
                            </thead>
                            {
                                filterdAgents && filterdAgents.map((application, i)=>(
                                <AgentApplicationListCard application={application} i={i} key={i} handleToggleAssignAgentModal setActiveSlug/>
                                ))
                            }
                            </table>
                        </div>
                        )
                    }
                    </div>
                </div>
            ):(
              <div className='h-screen flex flex-col justify-center items-center gap-10'>
                <NoData
                    icon={<Search size={50}/>}
                >
                    <h3 className='text-normal'>No Billings <br /> Applications Found</h3>
                </NoData>
                <div className='w-3/12'>
                    <CreateButton open={open} btnText={'Create Billing'}/>
                </div>
              </div>
            )
        }
      <ModalDialog
        modalOpen={openModal}
        modalClose={close}
      >
        <h3 className='font-bold text-sm py-2 border-b'>create new user</h3>
        <Formik
            initialValues={{ email: "", phone_number:"", password: "", }}
            validationSchema={CreateOperationOrBillingFormSchema}
         
            onSubmit={async (values, { setSubmitting }) => {
              const billingAgentsUserPayload = {
                email: values.email,
                password: values.password,
                phone_number: values.phone_number,
                country_code: selectedCountry.dial_code,
                is_operation: true
              }
              const billingsUserPayload = {
                email: values.email,
                password: values.password,
                phone_number: values.phone_number,
                country_code: selectedCountry.dial_code,
                is_billing: true
              }
              try {
                const { status, data, error } = await createUser(selectedUserType === 'operation' ? billingAgentsUserPayload : billingsUserPayload);
                console.log(status)
                console.log(data)
                if (status === 201) {
                  toast.success(data?.message);
                  close();
                }
                if(status !==201){
                  toast.error(error)
                }
              } catch (error:any) {
                return error
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
              <div>
                <TextInput
                  label='user email'
                  name='email'
                  icon={undefined}
                  type='email'
                  placeholder='email'
                />
                <>
                <h3 className='text-sm font-normal mb-1 ml-1'>Enter User Phone Number</h3>
                  <div className='flex items-center relative'>
                    <div className='md:w-3/12'>
                        <div className='p-3 flex items-center gap-3 border cursor-pointer text-sm' onClick={handleToggleCountrySearch}>
                            <h3>{selectedCountry.flag}</h3>
                            <span>{selectedCountry.dial_code}</span>
                        </div>
                        <div className=''>
                            {
                                showCountrySearch && (
                                    <div className='w-full h-64 p-5 shadow-lg mt-10  absolute top-10 left-0 bg-white z-10 overflow-y-scroll'>
                                        <input 
                                            type="search"
                                            className='w-full rounded-md px-5 py-3 mt-2 outline-none border'
                                            placeholder='search country code'
                                            value={searchCountryQuery}
                                            onChange={handleCountrySearchQuery}
                                        />
                                        <ul className=''>
                                            {
                                                filterdCountries.map((country, i)=>(
                                                    <li className='flex items-center gap-3 border-b py-2 cursor-pointer' key={i} onClick={()=>{
                                                        handleSelectCountry(country)
                                                        handleToggleCountrySearch()
                                                    }}>
                                                        <span>{country.flag}</span><h3>{country.name}({country.dial_code})</h3>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                     <PhoneInput
                      name='phone_number'
                      icon={undefined}
                      type='number'
                      placeholder='user phone number'
                    />
                  </div>
                </>
                <div className='relative'>
                  <TextInput
                    label='user password'
                    name='password'
                    icon={undefined}
                    type='password'
                    placeholder='password'
                  />
                </div>
                <div className="w-full dropdown border rounded-md text-sm">
                  <select className="select select-ghost w-full" onChange={handleChange} value={selectedUserType}>
                    <option disabled value={''} className='w-full'>Select type of account</option>
                    {userTypes.map((option, i) => (
                        <option key={i} value={option}>
                          {option}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {
                isSubmitting ? 
                <div className='flex justify-center items-center py-5'><SimpleSpinner size={'lg'}/></div> :
                (
                  <Button 
                  // type='submit' 
                  disabled={isSubmitting}
                  isBorder={false}
                  >
                    {isSubmitting ? "creating your account" : "Create " +  selectedUserType + ' user account'}
                  </Button>
                )
              }
            </Form>
            )}
          </Formik>
      </ModalDialog>
    </div>
  )
}

export default ListBillings