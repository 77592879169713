
import axios, { AxiosResponse, AxiosError } from "axios";
import coreClient from "../../services/coreApi";
import { baseURL } from "../../services/baseURL.service";

interface Payload {
  email:string,
  phone_number:string,
  country_code:string,
  password:string,
}

interface CreateUserResponse {
  status:Number,
  data?:any,
  error?:string
}

export const useCreateUser=()=>{

  const createUser = async (payload: Payload): Promise<CreateUserResponse> => {
    try {
      const response: AxiosResponse = await coreClient.post(
        `${baseURL}/auth/admin-signs-users/`,
        payload
      );
      return { status: response.status, data: response.data };
    } catch (error: any) {
      let errorResponse: CreateUserResponse = { status: 500, error: "Unknown error occurred" };

      if (axios.isAxiosError(error) && error.response) {
        errorResponse = {
          status: error.response.status,
          error: error.response.data?.message || "Error occurred",
        };
      }
      return errorResponse;
    }
  };

  return createUser;
}