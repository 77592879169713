
import axios, { AxiosResponse, AxiosError } from "axios";
import { baseURL } from "../services/baseURL.service";

interface Payload {
    otp:string,
}

interface VerifyOtpResponse {
  status:Number,
  data?:any,
  error?:string
}

export const useVerifyOtp=()=>{
  const verifyOtp = async (payload:Payload): Promise<VerifyOtpResponse>=>{
    try {
      const response:AxiosResponse = await axios.post(`${baseURL}/auth/verify-code/`, { otp: payload.otp });
      return { status:response.status , data: response }
    } catch (error:any) {
      if(axios.isAxiosError(error)){
        const axiosError:AxiosError = error
        return { status: axiosError.response?.status || 500, error:error.message }
      }else{
        return { status: 500, error:'an unknown error occured'}
      }
    }
  }
  return verifyOtp
}