
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from "formik";
import { FaEye, FaEyeSlash, FaLongArrowAltRight } from "react-icons/fa";
import { PiHandshakeThin } from "react-icons/pi";
import { toast } from 'sonner';
import { useSignIn } from '../../hooks/useSignIn';
import { TextInput } from '../../components/forms/inputs';
import { Button } from '../../components/button';
import { LoginFormSchema } from '../../utils/yupSchemas.js';
import tokenService from '../../services/token.service';
import LocationSearch from '../../components/google/LocationSearch';
import axios from 'axios';
import { pageURL } from '../../components/navigation/pagesURL';
import logo from '../../assets/png/showingxchange-logo.png'




const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorProcessingMsg, setErrorProcessingMsg] = useState<string>("");
  const navigateTo = useNavigate();
  const signIn = useSignIn();



  const handleTogglePassword:any = () => {
    setShowPassword(!showPassword);
  }; 


 

  return (
    <div className='h-screen'>
        <div className='h-full flex flex-col md:flex-row font-primary bg-[#f5f4f4]'>
          <div className='h-full w-full lg:w-1/2 px-5  flex flex-col items-center justify-center gap-8 '>
            <div className='shadow-auth2 bg-white max-w-[442px] md:w-[442px] px-7 py-10 rounded-2xl '>
              <div className='mb-10'>
                <div className='flex items-center gap-2 justify-center'>
                  <Link to={'/'}>
                    <img src={logo} alt="" className='rounded-full h-24 w-24 my-5 border border-customPrimary p-1'/>
                  </Link>
                  {/* <span className='text-sm font-normal'><PiHandshakeThin className='h-6 w-6 text-primary'/></span>
                  <span className='text-sm font-normal'>ShowingXchange</span> */}
                </div>
                <h4 className='text-sm font-normal text-center'>Account Login <br /> <span className='text-xsm font-light'>Enter your Credentials to Access your account</span></h4>
              </div>
              <div>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={LoginFormSchema}
                  enableReinitialize={true}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      const { status, data } = await signIn({
                        email: values.email,
                        password: values.password,
                      });
                      if (status === 200) {
                        tokenService.setUser(data?.data);
                        (data?.data?.user.is_agent===true) ? navigateTo(pageURL.appDownload)
                        : (data?.data?.user.is_admin===true) ? navigateTo(pageURL.adminDashboard)
                        : (data?.data?.user.is_operation===true && data?.data?.user.is_application_approved==='Approved') ? navigateTo(pageURL.adminDashboard)
                        : (data?.data?.user.is_billing===true && data?.data?.user.is_application_approved==='Approved') ? navigateTo(pageURL.adminDashboard)
                        : ((data?.data?.user.is_billing===true || data?.data?.user.is_operation===true) && data?.data?.user.is_application_approved==='Pending') ? navigateTo(pageURL.applicationCheck)
                        : navigateTo("/exchange/err");
                        const promise = () => new Promise((resolve) => setTimeout(() => resolve({ name: 'Sonner' }), 2000));
                        toast.promise(promise, {
                            loading: 'Loading...',
                            success: (data) => {
                                return `successfully logged in!`;
                            },
                            error: 'Error',
                        });
                      }
                    } catch (error:any) {
                      if (error.response && error.response.data && error.response.data) {
                        toast.error(`${error.response.data.message}`);
                        if(error.response.data.data.is_phone_verified===false){
                          navigateTo(pageURL.verifyPhoneNumber);
                        }
                      } else if (error.request) {
                          toast.error('No response from server. Please try again later.');
                      } else {
                          toast.error(`${error.message}`);
                      }
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div>
                        <TextInput
                          label='Email ID'
                          name='email'
                          icon={undefined}
                          
                          // autoComplete='email'
                          type='email'
                          placeholder='Your email'
                        />
                        <div className='relative'>
                          <TextInput
                            label='Password'
                            name='password'
                            icon={undefined}
                            
                            type={showPassword ? "text" : "password"}
                            placeholder='Your password'
                            handleTogglePassword={handleTogglePassword}
                          />
                        </div>
                      </div>
                      <Button 
                      // type='submit' 
                      disabled={isSubmitting}
                      isBorder={false}
                      >
                        {isSubmitting ? "accessing account" : "Sign in"}
                      </Button>
                    </Form>
                  )}
                </Formik>
              
                {/* <p className='text-center text-xsm'>
                  Don't have an account yet?
                  <span
                    onClick={() => navigateTo("/register")}
                    className='text-customPrimary cursor-pointer font-bold ml-2'
                  >
                    {" "}
                    Sign up
                  </span>{" "}
                </p> */}
                  <p
                    onClick={() => navigateTo("/xchange/reset-password")}
                    className='text-center text-xsm text-red-500 cursor-pointer py-6'
                  >
                    Forgot password?
                  </p>
              </div>
            </div>
          </div>
          <div className='md:w-1/2 bg-heroImage bg-cover bg-no-repeat bg-center relative'>
            <div className='h-full w-full bg-customPrimary/70 absolute top-0 left-0'>

            </div>
          </div>
        </div>
    </div>
  )
}

export default Login