import React from 'react'

const Support = () => {
  return (
    <div>
      <div className='flex justify-center items-center md:min-h-64 bg-customPrimary text-center font-secondary'>
          <div className='w-6/12 text-white'>
            <h2 className='text-lg font-bold'>ShowingXchange Support</h2>
            <p className='text-sm font-normal'>
              We're here to assist you with any questions or issues you may have. <br /> Our goal is to ensure you have a seamless and
              enjoyable experience using our platform. Below you'll find information on how to get help, FAQs, and contact details.
            </p>
          </div>
      </div>
      <div className='flex flex-col md:flex-row justify-between gap-5 items-center md:px-10 py-5 font-secondary'>
        <div className='md:w-6/12'>
          <h2 className='text-lg font-bold'>Getting Started</h2>
          <p className='text-sm font-normal'>
            Welcome to ShowingXchange! We're excited to have you on board. Here's a few tips to get you started:
          </p>
          <ul className='list-disc pl-5'>
            <li>Create an account</li>
            <li>Explore our listings</li>
            <li>Find your perfect home</li>
          </ul>
        </div>
        <div className='md:w-6/12 bg-[#e3e3e3]/40 p-10'>
        <div className='bg-white p-10 rounded-md'>
          <h2 className='text-sm font-bold'>Send us a message!</h2>
          <p className='text-sm font-normal my-5'>
            If you need assistance, our support team is here to help. Just complete the form below.
          </p>
          <div>

          </div>
        </div>
        </div>
      

      </div>
    </div>
  )
}

export default Support