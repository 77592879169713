import { FaPlus } from "react-icons/fa6";

interface IAddButton {
    open: () => void;
    btnText: string;
}

const CreateButton: React.FC<IAddButton> = ({ open, btnText }) => {
  return (
    <button 
        onClick={open}
        className='w-full flex items-center justify-between 
        gap-3 bg-customPrimary rounded-sm py-3 px-5 font-normal 
        text-white text-sm mb-3 hover:bg-customSecondary ease-in duration-300'>
        {btnText} <FaPlus className='text-white' />
    </button>
  );
}

export default CreateButton;
