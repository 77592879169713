



import * as React from 'react'
import { useField } from "formik"



interface TextInputProPs {
  name:string,
  type:string
  placeholder:string,
  icon?:React.ReactNode,
  handleTogglePassword?:React.MouseEventHandler<HTMLSpanElement> | undefined,
}

export default function PhoneInput ({ ...props }:TextInputProPs) {
  
    const [field, meta] = useField(props);
    return (
        <div className="w-9/12 grid grid-cols-1 my-3 relative">
            <input 
            className="w-full text-[#989093] text-sm font-light py-3 px-2 outline-0 rounded-tr-[8px] rounded-br-[8px] border" {...field} {...props} />
            {meta.touched && meta.error ? (
                <p className="text-red-500 absolute -bottom-7">{meta.error}</p>
            ) : null}
        </div>
    );
};

