

export const faqs = [
    {
        title: 'What is ShowingXchange all about?',
        description: 'So, picture this: you\'re a real estate agent with a packed schedule, right? But you\'ve got clients wanting to see properties. What do you do? That\'s where the ShowingXchange app comes in handy. It lets you pass those showing requests to other agents in your area who\'ve got some free time. You pay them a small fee that you decide and set, let\'s say around 50 bucks, and everyone wins.You get to free up your schedule, another agent makes some extra cash in their downtime, and most importantly, your client gets to see the property they\'re interested in without any delays. And trust me, in real estate, timing is everything when it comes to closing a deal.It\'s basically like a win-win-win situation. The busy agent can take on more clients, the available agent can earn some extra money, and the client doesn\'t have to wait around.'
    },
    {
        title: 'Why Now? ',
        description: ' More than 85% of real estate agents consider property showings to be the most time-consuming part of the entire transaction process. XYZ offers a cutting-edge solution that leverages the latest technology to streamline this task, saving agents valuable time and allowing them to focus on what truly matters—closing deals and growing their business, and keeping their clients happy at the same time. '
    },
    {
        title: 'Where can I get the ShowingXchange app?',
        description: ' The showing exchange app is available for download on both IOS( App store) and Android( Google play store). Download links also available on the main website- www.showingxchange.com'
    },
    {
        title: 'How do I sign up? Or What are the steps involved in using this app?',
        description: ' So, once you download the app, you will create your login and submit a simple application providing your real estate license info. The application gets reviewed by our backend operations team and approved at the earliest. ( mostly within 24 hours of submission).'
    },
    // {
    //     title: 'Are there any fees for signing up?  ',
    //     description: ' There is a $19.99 application processing fees. We are running a trade show special where if you sign up this week till 8/25 ( Sunday) you will receive 50% off using this coupon code- FLR2024. We encourage you to take advantage of it.'
    // },
    {
        title: 'What happens if the delegated agent doesn\'t show up?',
        description: ' Once a showing request accepting agent has accepted the showing, he/she is able to see the contact information of the creating agent and vice versa. They are able to communicate directly in case of any last minute issues. '
    },
    {
        title: 'How are fees determined and processed for delegating showing requests?',
        description: ' We have the Showing Request Creating Agent who creates the request and decides the fees that they want to pay to the Accepting Agent. We recommend offering $50 or more per showing but in the end it is up to the Creating agent.  Offers for fees should be adjustable based on factors like location or duration. All payments are processed securely through the app with direct deposits to agents. '
    },
    {
        title: 'Is there a rating system for agents who perform delegated showings?',
        description: ' Yes, we are implementing  a comprehensive rating system feature soon where both clients and delegating agents can rate showing agents based on punctuality, professionalism, and property knowledge. '
    },
    {
        title: 'How does the app protect client information and privacy?',
        description: ' All personal information is encrypted and securely stored. Showing agents only receive necessary information, and all users agree to strict confidentiality terms. '
    },
    {
        title: 'Can Showing Request Creating Agents choose or request specific agents for delegated showings?',
        description: ' Currently, the app assigns the most suitable available agent. However, we\'re considering a feature to allow requesting preferred agents based on past experiences. '
    },
    {
        title: 'What liability coverage is in place for delegated showings?',
        description: ' All participating agents must have current professional liability insurance which is generally a standard practice. '
    },
    {
        title: 'How does the app handle scheduling conflicts or last-minute changes?',
        description: ' Once the showing is accepted by a Showing Request Accepting Agent, he receives the contact information of the creating agent and can contact him/her for any last minute accommodations. '
    },
    {
        title: 'Are there any restrictions on which properties can be delegated for showing?',
        description: ' Generally, all listed properties are eligible. However, agents can set specific requirements or restrictions for their listings if necessary. '
    },
    {
        title: 'How does the app ensure compliance with local real estate regulations?',
        description: ' We stay updated with regulations in all operating areas, only allowing licensed agents to participate. '
    },
    {
        title: 'Can agents set preferences for the properties they\'re willing to show?',
        description: ' Yes, agents can set preferences for the location of the properties they want to show.'
    },
    {
        title: 'What happens if a client wants to make an offer after a delegated showing?',
        description: ' The showing agent directs the client back to the listing agent for all offer-related matters. Our app is designed for showings only, not to interfere with the sales process. '
    },
    {
        title: 'How does the app handle commission splits for successful sales from delegated showings?',
        description: ' Our app doesn\'t involve itself in commission structures. The showing fee is the only payment we process. Commission arrangements remain between the involved agents and their brokerages. '
    },
    {
        title: 'Is there a limit to how many showings an agent can delegate or accept?',
        description: ' There\'s no set limit, but we monitor activity to ensure quality. The requests are picked up on a first come first serve basis. '
    },
    {
        title: 'How does the app verify the credentials of participating agents?',
        description: ' We require the users toAdd their profile pic, and the drivers license to cross verify the credentials. '
    },
    {
        title: 'What kind of support is available if there are issues during a delegated showing?',
        description: ' We offer real-time support through the app and have a dedicated helpline for urgent issues during showings. '
    },
    {
        title: 'Can agents from different brokerages use the app to delegate to each other?',
        description: ' Yes, our app allows cross-brokerage delegation, subject to local regulations and brokerage policies. '
    },
    {
        title: 'How does the app handle feedback from clients after delegated showings?',
        description: ' Clients can provide feedback through our rating system. This feedback is shared with both the showing and delegating agents to maintain service quality. '
    },
    {
        title: 'Are there any additional fees for using the app beyond the delegation fee?',
        description: ' There\'s no cost to download or maintain an account. The only fees are for actual delegated showings.'
    },
    {
        title: 'How does the app integrate with existing real estate CRM systems?',
        description: ' For systems we don\'t directly integrate with, we provide easy export options for showing data '
    },
    {
        title: 'What security measures are in place to protect property and client information?',
        description: ' We use bank-level encryption for all data transmissions and the data at rest.'
    },
    {
        title: 'Is the app available for both iOS and Android?',
        description: ' Yes, Showing exchange is available on both iOS and Android platforms.'
    },
    {
        title: 'How does the app vet or verify the agents who can perform showings?',
        description: ' We require agents to submit their license information and cross verify with their drivers license. We also check if their real estate license is active or not.'
    },
    {
        title: 'How does the app handle scheduling conflicts or last-minute changes?',
        description: ' The app sends real-time notification reminders to the showing request accepting agents. Both agents also have their contact information for any further communication that maybe needed to coordinate this further. '
    },
    {
        title: 'What are the costs associated with using the app?',
        description: ' There\'s no monthly subscription fee at all. You only pay per showing request delegation.'
    },
    {
        title: 'How does the app handle key management and property access?',
        description: ' Currently we don’t have integration with the lockbox providers but it is a feature being worked on.'
    },
    {
        title: 'Is there a limit to the number of showings an agent can delegate or accept?',
        description: ' There are no hard limits, but the app includes features to prevent overbooking and ensure fair distribution of opportunities.'
    },
    {
        title: 'Is there a feature for virtual or video showings?',
        description: ' Yes, the app includes a feature for virtual showings to record pics and videos of the property for walkthroughs etc. '
    },
    {
        title: 'What kind of customer support does the app offer',
        description: ' We offer 24/7 customer support via in-app chat, email, and phone for urgent issues.'
    },
    {
        title: 'How does the app handle different time zones for scheduling?',
        description: ' The app automatically adjusts for different time zones, displaying all times in the user\'s local time zone to avoid confusion'
    },
    {
        title: 'How does the app handle cancellations or no-shows?',
        description: ' The app tracks cancellations and no-shows, with a system to fairly compensate involved agents for last-minute cancellations.'
    },
    {
        title: 'Is there a feature for agents to block out unavailable times?',
        description: ' No, but accepting agents decide if or not they want to accept a particular request based on their availability.'
    },
    {
        title: 'How does the app handle language preferences for international clients or agents?',
        description: ' The app will soon support multiple languages and will be able to  automatically translate property descriptions and feedback forms based on user preferences.'
    },
    {
        title: 'How does the app handle showing requests for properties with specific showing instructions or restrictions?',
        description: ' The app allows listing agents to input detailed showing instructions, which are prominently displayed to showing agents before each appointment.'
    },
    {
        title: 'How does the app ensure fair distribution of showing opportunities among agents?',
        description: ' The app uses an algorithm that considers factors like agent availability, expertise, and past performance to ensure fair distribution of showing opportunities.'
    },
    {
        title: 'Is there a feature for agents to easily communicate special property features or recent updates?',
        description: ' Listing agents can highlight special features or recent updates in a dedicated section of the property profile, ensuring showing agents are well-informed.'
    },
]