import React, { useState, useEffect } from 'react';
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom';
import { useVerifyOtp } from '../../hooks/useVerifyOtp';
import { useResendOtp } from '../../hooks/useResendOtp';
import { CustomTextInput } from '../../components/forms/inputs';
import { DefaultButton } from '../../components/button';
import { FaLongArrowAltRight } from "react-icons/fa";
import { SimpleSpinner } from '../../components/loaders';
import { pageURL } from '../../components/navigation/pagesURL';

interface VerifyAdminTokenProps {
    otp: string;
}

const VerifyOtp = () => {
    const [loading, setLoading] = useState(false);
    const verifyOtp  = useVerifyOtp();
    const resendOtp  = useResendOtp();
    const navigateTo  = useNavigate();

    const [formData, setFormData] = useState<VerifyAdminTokenProps>({
        otp: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmitVerificationData = async() => {
        // console.log('Submitting verification data', payload);
        const payload = {
            otp:formData.otp
        }
        try {
            setLoading(true)
            const { status, data } = await verifyOtp(payload);
            console.log(data);
            if(status===200){
                const promise = () => new Promise((resolve) => setTimeout(() => resolve({ name: 'Sonner' }), 2000));
                toast.promise(promise, {
                    loading: 'Loading...',
                    success: (data) => {
                        return `otp successfully verified`;
                    },
                    error: 'Error',
                });
                navigateTo(pageURL.signIn)
            }
        } catch (error:any) {
            if (error.response && error.response.data && error.response.data) {
                toast.error(`${error.response.message}`);
              } else if (error.request) {
                  toast.error('No response from server. Please try again later.');
              } else {
                  toast.error(`${error.message}`);
              }
        }finally{
            setLoading(false);
        }

        
        
    };

  

    const isFormValid = formData.otp !== '' || formData.otp.length < 4;

    return (
        <div className='h-screen flex flex-col justify-center items-center font-primary'>
            <div>
                <h3>Kindly verify your otp sent to </h3>
                <div>
                    <CustomTextInput
                        label='Enter otp code'
                        handleChange={handleChange}
                        inputAttrs={{
                            type: 'text',
                            name: 'otp',
                            placeholder: '425274 - otp code',
                            value:formData.otp
                        }}
                    />
                    <div className='mt-5'>
                        <DefaultButton
                            isBorder={false}
                            icon={<FaLongArrowAltRight />}
                            disabled={!isFormValid}
                            handleClick={handleSubmitVerificationData}
                        >
                            {
                                loading ? <SimpleSpinner size='xs' /> : <span>proceed to confirm otp</span>
                            }
                        </DefaultButton>
                    </div>
                    {/* <div className='py-5'>
                        <Text className=''>Didn’t receive any code?
                            <TouchableOpacity onPress={handleResendOtp}>
                            <Text className='border-b text-primary translate-y-1'> Resend OTP Again</Text>
                            </TouchableOpacity> 
                        </Text>
                        </View>
                        <View className=''>
                        <Text className=''>Request new code in 00:30s </Text>
                        </View>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default VerifyOtp;
