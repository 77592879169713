// import React, { useField } from "formik";

// export default function TextInput({
//   label,
//   icon,
//   passwordIcon,
//   onClick,
//   handleTogglePassword,
//   ...props
// }) {
//   const [field, meta] = useField(props);
//   return (
//     <div className='grid grid-cols-1 relative mt-2 mb-4'>
//       <label
//         htmlFor={props.id || props.name}
//         className='text-xsm md:text-[14px] font-normal mb-[4px] ml-1 capitalize'
//       >
//         {label}
//       </label>
//       {icon && (
//         <span className='absolute bottom-[2.4rem] left-2'>
//           {<img src={icon} alt='' />}
//         </span>
//       )}
//       <div className='relative'>
//         <input
//           className={`${
//             icon && "pl-7"
//           } w-full text-[#989093] text-sm font-light py-2 px-2 outline-0 
//                     rounded-[8px] border border-[#E4E6F1] pl-4 placeholder:text-xsm`}
//           {...field}
//           {...props}
//           onClick={onClick}
//         />
//         <span
//           onClick={handleTogglePassword}
//           className='absolute bottom-3 right-3 cursor-pointer'
//         >
//           {passwordIcon}
//         </span>
//       </div>
//       {meta.touched && meta.error ? (
//         <p className='text-red-500 text-xsm'>{meta.error}</p>
//       ) : null}
//     </div>
//   );
// }



import * as React from 'react'
import { useField } from "formik"

// interface TextInputProPs {
//   label:string,
//   name:string,
//   type:string
//   placeholder:string,
//   icon?:React.ReactNode,
//   passwordIcon?:React.ReactNode,
//   handleTogglePassword?:React.MouseEventHandler<HTMLSpanElement> | undefined,
// }

// export default function TextInput({
//   name,
//   placeholder,
//   type,
//   label,
//   icon,
//   passwordIcon,
//   // onClick,
//   handleTogglePassword,
//   ...props
// }:TextInputProPs) {
//   const [field, meta] = useField({ name, type});

//   const inputType = type === 'password' ? 'password' : 'text';
  
//   return (
//     <div className='grid grid-cols-1 relative mt-2 mb-4'>
//       <label
//         htmlFor={name}
//         className='text-xsm md:text-[14px] font-normal mb-[4px] ml-1 capitalize'
//       >
//         {label}
//       </label>
//       {icon && (
//         <span className='absolute bottom-[2.4rem] left-2'>
//           {/* {<img src={icon} alt='' />} */}
//           { icon }
//         </span>
//       )}
//       <div className='relative'>
//         <input
//           className={`${
//             icon && "pl-7"
//           } w-full text-[#989093] text-sm font-light py-2 px-2 outline-0 
//                     rounded-[8px] border border-[#E4E6F1] pl-4 placeholder:text-xsm`}
//           {...field}
//           {...props}
//         />
//         <span
//           onClick={handleTogglePassword}
//           className='absolute bottom-3 right-3 cursor-pointer'
//         >
//           {passwordIcon}
//         </span>
//       </div>
//       {meta.touched && meta.error ? (
//         <p className='text-red-500 text-xsm'>{meta.error}</p>
//       ) : null}
//     </div>
//   );
// }


interface TextInputProPs {
  label:string,
  name:string,
  type:string
  placeholder:string,
  icon?:React.ReactNode,
  handleTogglePassword?:React.MouseEventHandler<HTMLSpanElement> | undefined,
}

export default function TextInput ({ label, ...props }:TextInputProPs) {
  
    const [field, meta] = useField(props);
    return (
        <div className="grid grid-cols-1 my-3">
            <label htmlFor={props.name} className="text-sm font-normal mb-1 ml-1">{label}</label>
            <input className="text-[#989093] text-sm font-light py-3 px-2 outline-0 rounded-[8px] border border-[#DDDADB] pl-4" {...field} {...props} />
            {meta.touched && meta.error ? (
                <p className="text-red-500">{meta.error}</p>
            ) : null}
        </div>
    );
};

