import React from 'react';
// import { TopHeader } from '../../components/header';
import { Hero } from '../../components/hero';
import { Sections } from '../../components/sections';
import { Footer } from '../../components/footer';
import { DesktopNavigation } from '../../components/header';

const Landing = () => {
  return (
    <div>
      <DesktopNavigation />
        <Hero />
        <Sections />
        <Footer />
    </div>
  )
}

export default Landing