import React from 'react'
import { motion } from 'framer-motion';
import { faqs } from '../../utils/faqs';

const FAQS = () => {

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
    }

  return (
    <div className='flex flex-col md:flex-row my-10 font-primary px-5 py-10'>
        <div className='md:w-5/12 flex flex-col justify-cente items-center'>
            <div className=''>
                <motion.h1 
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: 1.2, delay: 0.2 }}
                className='font-xl font-xbold text-[2.3rem] md:text-[60px] 
                leading-[2rem] md:leading-[4rem]'>
                    Frequently <br /> Asked Questions
                </motion.h1>
                <div className='mt-5 text-sm'>
                    <p>
                        Everything you need to know about the app and how it works 😊
                    </p>
                    {/* <p>
                        can't find an answer? 
                    </p>
                    <ul>
                        <li><strong>Facebook:</strong> <a href="https://www.facebook.com/showingXchange/" className=' hover:text-yellow-400 duration-300'>Facebook.com/ShowingXchange</a></li>
                        <li><strong>Twitter:</strong> <a href="https://x.com/showingxchange_" className=' hover:text-yellow-400 duration-300'>@showingxchange_</a></li>
                        <li><strong>Instagram:</strong> <a href="https://www.instagram.com/showingxchange" className=' hover:text-yellow-400 duration-300'>@showingXchange</a></li>
                    </ul> */}
                </div>
            </div>
        </div>
        <div className='md:w-7/12 mt-5 md:mt:0 text-slate-600'>
            <div className="join join-vertical w-full text-sm">
                {
                    faqs.map((queston, index)=>(
                        <div className="collapse collapse-arrow join-item border-base-300 border" key={index}>
                            <input type="radio" name="my-accordion-4" defaultChecked />
                            <div className="collapse-title font-medium">{queston.title}</div>
                            <div className="collapse-content">
                            <p>{queston.description}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default FAQS