
import { InstagramLogoIcon } from '@radix-ui/react-icons';
import { Twitter, Instagram, Youtube, Facebook } from 'lucide-react';

export const socialLinks = [
    {
        name: 'X',
        link: 'https://x.com/showingxchange_',
        icon: <Twitter />
    },
    {
        name: 'instagram',
        link: 'https://www.instagram.com/showingxchange',
        icon: <Instagram />
    },
    {
        name: 'facebook',
        link: 'https://www.facebook.com/showingXchange',
        icon: <Facebook />
    },
    {
        name: 'youtube',
        link: 'https://www.youtube.com/@ShowingXchange',
        icon: <Youtube />
    },
]