import React from 'react';
import { PageHeader } from '../../components/page-header';
import curiousPeaople from '../../assets/jpg/curiosity-people.jpeg';
import { DesktopNavigation } from '../../components/header';
import { Footer } from '../../components/footer';
import { FAQS } from '../../components/faq';

const Contact = () => {
  return (
    <div>
      <DesktopNavigation />
        <PageHeader
            image={curiousPeaople}
            label='Contact Us'
            subLabel='Our Team is Available 24/7'
      />
      <div className='w-full p-5 md:w-10/12 m-auto md:py-10 font-primary'>
        <h2 className='text-lg font-bold my-10 leading-10 text-gray-700'>We're Here to Help!</h2>
        <div className='mt-4'>
          <p>Got a question? Need support? Just want to say hello? We'd love to hear from you! Whether you're a seasoned agent, new to the game, or just curious about how <strong>ShowingXchange</strong> can transform your real estate experience, we're here to help you every step of the way.
          </p>
        </div>
        <div className='mt-4'>
         <h2 className='text-[1.4rem] font-bold text-customPrimary'>Get in Touch</h2>
          <ul>
              <li><strong>Email:</strong> <a href="mailto:support@showingxchange.com">support@showingxchange.com</a></li>
              <li><strong>Phone:</strong> <a href="tel:18005551234">1-9045697483</a></li>
          </ul>
        </div>
        <div className='mt-4'>
         <h2 className='text-[1.4rem] font-bold text-customPrimary'>Support Center</h2>
         <p>Visit our <a href="#">Support Center</a> for quick answers to common questions, tutorials, and troubleshooting guides. We've got all the resources you need to make the most of <strong>ShowingXchange</strong>.</p>
        </div>
        <div className='mt-4'>
         <h2 className='text-[1.4rem] font-bold text-customPrimary'>Connect With Us</h2>
         <p>Stay in the loop! Follow us on social media for the latest updates, tips, and success stories from agents like you.</p>
          <ul>
              <li><strong>Facebook:</strong> <a href="https://www.facebook.com/showingXchange/" className='text-blue-400 hover:text-yellow-400 duration-300'>Facebook.com/ShowingXchange</a></li>
              <li><strong>Twitter:</strong> <a href="https://x.com/showingxchange_" className='text-blue-400 hover:text-yellow-400 duration-300'>@showingxchange_</a></li>
              <li><strong>Instagram:</strong> <a href="https://www.instagram.com/showingxchange" className='text-blue-400 hover:text-yellow-400 duration-300'>@showingXchange</a></li>
          </ul>
        </div>
        <div className='mt-4'>
         <h2 className='text-[1.4rem] font-bold text-customPrimary'>Feedback</h2>
         <p>Your feedback is invaluable to us! If you have suggestions or ideas to make <strong>ShowingXchange</strong> even better, don't hesitate to reach out. We're all about continuous improvement, and your insights help us serve you better.</p>
        </div>
        <div className='mt-4'>
        {/* <p>Interested in partnering with us or learning more about our services? Contact our business development team at <a href="mailto:business@appname.com">business@appname.com</a>. We're always open to exciting new collaborations.</p> */}
        <p><strong>Let's make your real estate journey a success, together.</strong> Drop us a line anytime—we're just a click or call away!</p>
        </div>
      </div>
      <div>
        <FAQS />
      </div>
      <Footer />
    </div>
  )
}

export default Contact