import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { FaGifts } from "react-icons/fa6";
import { MdContactSupport } from "react-icons/md";
import logo from '../../assets/png/showingxchange-logo.png'
import { pageURL } from '../navigation/pagesURL';

const DesktopNavigation = () => {
    const navigateTo = useNavigate();
    const navItems = [
        // {
        //     name:'Home',
        //     url:'/',
        //     icon:<FaHome />
        // },
        {
            name:'Login',
            url:pageURL.signIn,
            // icon:<MdContactSupport />
        },
        {
            name:'About Us',
            url:pageURL.about,
            // icon:<TbListDetails />
        },
        // {
        //     name:'FAQs',
        //     url:pageURL.faq,
         
        // },
        {
            name:'Contact Us',
            url:pageURL.contact,
            // icon:<MdContactSupport />
        },
        
    ]

    
  return (
    <div className='hidden w-full m-auto  md:flex items-center justify-between bg-white font-primary py-3 px-5'>
        <div className='flex items-center gap-2'>
            <img src={logo} 
                onClick={()=>navigateTo('/')}
                className='w-12 h-12 border-2 border-customPrimary rounded-full cursor-pointer p-1' alt="ShowingXchange" 
            />
            <h4>ShowingXchange.com | Convenient Property Showings, Anywhere, Anytime.</h4>
        </div>
        <ul className='flex items-center'>
            {
                navItems && navItems.map((item, index)=>(
                    <li 
                    onClick={()=>navigateTo(item.url)}
                    key={index}
                    className='flex items-center gap-1 text-blue text-xsm cursor-pointer hover:text-yellow 
                    ease-in duration-500 font-medium'><span className='ml-5'>{item?.icon}</span>{item.name}</li>
                ))
            }
        </ul>
    </div>
  )
}

export default DesktopNavigation