
import axios, { AxiosResponse, AxiosError } from "axios";
import { baseURL } from "../services/baseURL.service";

interface Payload {
  email:string,
  password:string
}

interface SignInResponse {
  status:Number,
  data?:any,
  error?:string
}

export const useSignIn=()=>{
  const signIn = async (payload:Payload): Promise<SignInResponse>=>{
    try {
      const response:AxiosResponse = await axios.post(`${baseURL}/auth/custom-login/`, payload);
      return { status:response.status , data: response }
    } catch (error:any) {
      // if(axios.isAxiosError(error)){
      //   const axiosError:AxiosError = error
      //   return { status: axiosError.response?.status || 500, error:error.message }
      // }else{
      //   return { status: 500, error:'an unknown error occured'}
      // }
      throw error
    }
  }
  return signIn
}