import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='h-screen w-3/4 m-auto grid grid-cols-1 border'>
        <iframe 
            src="https://showingxchange-public-access.s3.us-east-2.amazonaws.com/docs/privacypolicy.html"
            width="100%" 
            height="100%"
            title='legal'
        ></iframe>
    </div>
  )
}

export default PrivacyPolicy