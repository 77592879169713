
import React, { useState } from 'react';
import { MoveRight } from 'lucide-react';
import { toast } from 'sonner';
import { CustomTextInput } from '../inputs';
import { useVerifyAgentDocuments } from '../../../hooks/post/useVerifyAgentDocuments';




const VerifyAgentDocuments = ({ slug, handleVerifyDocumentsModal }:{ slug:any, handleVerifyDocumentsModal:any }) => {
    const [loading, setLoading] = useState(false);
    const verifyAgentDocuments = useVerifyAgentDocuments();

    const [formData, setFormData] = useState({
        validated_real_licence_expiry_date: '',
        validated_real_licence_status: '',
        validated_real_licence_address: '',
        validated_real_licence_type: '',
        comments_private: '',
        comments_public: '',
        validated_dl_first_name: '',
        validated_dl_middle_name: '',
        validated_dl_last_name: '',
        validated_dl_number: '',
    });
    const [formDataCheckBoxes, setFormDataCheckBoxes] = useState({
        validated_profile_dl_pic: false,
        is_real_licence_expiry_date_matched: false,
        is_real_licence_status_matched: false,
        is_real_licence_address_matched: false,
        is_dl_first_name_matched: false,
        is_dl_last_name_matched: false,
        is_dl_number_matched: false,
        is_dl_middle_name_matched: false,
        is_real_licence_type_matched: false,
      });

    const requiredFields = [
      'validated_real_licence_expiry_date',
      'validated_real_licence_status',
      'validated_real_licence_address',
      'validated_real_licence_type',
      'comments_private',
      'comments_public',
      'validated_dl_first_name',
      'validated_dl_middle_name',
      'validated_dl_last_name',
      'validated_dl_number',
    ]

      

      type CheckBoxKeys = keyof typeof formDataCheckBoxes;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
        const { name, value } = e.target;
        setFormData({
            ...formData, [name]:value
        })
    }

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFormDataCheckBoxes({
          ...formDataCheckBoxes,
          [name]: checked,
        });
      };

    const handleSubmitVerification = async()=>{
        
        requiredFields.forEach((field)=>{
          if(!formData[field as keyof typeof formData]){
            const fieldName = field.split('_').join(' ');
            toast.error(fieldName + ' cannot be empty')
          }
        })
        const combinedData = { ...formData, ...formDataCheckBoxes };
        
        try {
            setLoading(true);
            const { status, data } = await verifyAgentDocuments(combinedData, slug);
            console.log(status, 'stat======');
            console.log(data, 'data=======');
            if(status===200){
                handleVerifyDocumentsModal();
                toast.success('document submitted successfully');
            }
        } catch (error:any) {
            if (error.response && error.response.data && error.response.data) {
                toast.error(`${error.response.data.message}`);
              } else if (error.request) {
                  toast.error('No response from server. Please try again later.');
              } else {
                  toast.error(`${error.message}`);
              }
        }finally{
            setLoading(false)
        }

    }
  return (
    <div className='flex gap-5'>
      <div className='w-1/2'>
        <CustomTextInput
          label='Validate drivers licence first name'
          handleChange={handleChange}
          inputAttrs={{
            name: 'validated_dl_first_name',
            placeholder: 'Validate drivers licence first name',
            type: 'text',
            value: formData.validated_dl_first_name,
          }}
        />
        <CustomTextInput
          label='Validate drivers licence middle name'
          handleChange={handleChange}
          inputAttrs={{
            name: 'validated_dl_middle_name',
            placeholder: 'Validate drivers licence middle name',
            type: 'text',
            value: formData.validated_dl_middle_name,
          }}
        />
        <CustomTextInput
          label='Validate drivers licence last name'
          handleChange={handleChange}
          inputAttrs={{
            name: 'validated_dl_last_name',
            placeholder: 'Validate drivers licence last name',
            type: 'text',
            value: formData.validated_dl_last_name,
          }}
        />
        <CustomTextInput
          label='Validate drivers licence number'
          handleChange={handleChange}
          inputAttrs={{
            name: 'validated_dl_number',
            placeholder: 'Validate drivers licence phone',
            type: 'number',
            value: formData.validated_dl_number,
          }}
        />
        <CustomTextInput
          label='Real Licence Expiry Date'
          handleChange={handleChange}
          inputAttrs={{
            name: 'validated_real_licence_expiry_date',
            placeholder: '1930-09-27',
            type: 'text',
            value: formData.validated_real_licence_expiry_date,
          }}
        />
        <CustomTextInput
          label='Real Licence status'
          handleChange={handleChange}
          inputAttrs={{
            name: 'validated_real_licence_status',
            placeholder: 'Real Licence status',
            type: 'text',
            value: formData.validated_real_licence_status,
          }}
        />
        <CustomTextInput
          label='Real Licence type'
          handleChange={handleChange}
          inputAttrs={{
            name: 'validated_real_licence_type',
            placeholder: 'Real Licence type',
            type: 'text',
            value: formData.validated_real_licence_type,
          }}
        />
        <CustomTextInput
          label='Real Licence Address'
          handleChange={handleChange}
          inputAttrs={{
            name: 'validated_real_licence_address',
            placeholder: 'Real Licence Address',
            type: 'text',
            value: formData.validated_real_licence_address,
          }}
        />
      </div>
      <div>
        <CustomTextInput
        label='Real estate licence private comment'
        handleChange={handleChange}
        inputAttrs={{
            name: 'comments_private',
            placeholder: 'Real estate licence private comment',
            type: 'text',
            value: formData.comments_private,
        }}
        />
        <CustomTextInput
          label='Real estate licence public comment'
          handleChange={handleChange}
          inputAttrs={{
            name: 'comments_public',
            placeholder: 'Real Licence public comment',
            type: 'text',
            value: formData.comments_public,
          }}
        />
        <div className='py-5'>
        {/* {Object.keys(formDataCheckBoxes).map((checkboxName) => (
            <div key={checkboxName} className='flex items-center justify-between my-5'>
              <label htmlFor={checkboxName}>{checkboxName.replace(/_/g, ' ')}</label>
              <input
                type="checkbox"
                name={checkboxName}
                checked={formDataCheckBoxes[checkboxName as CheckBoxKeys]}
                onChange={handleCheckboxChange}
                className={`toggle border-slate-500 ${formDataCheckBoxes[checkboxName as CheckBoxKeys] ? 'bg-customPrimary' : 'bg-slate-500'} hover:bg-slate70`}
              />
            </div>
          ))} */}
          {Object.keys(formDataCheckBoxes).map((checkboxName) => {
            
            let label = checkboxName
              .replace(/^is/, '')
              .replace(/_/g, ' ')  
              .replace(/\bdl\b/gi, 'drivers licence')
              .replace(/\b(real)\b(?! estate)/gi, "$1 estate");

            return (
              <div key={checkboxName} className='flex items-center justify-between my-5'>
                <label htmlFor={checkboxName} className='capitalize'>{label}</label>
                <input
                  type="checkbox"
                  name={checkboxName}
                  checked={formDataCheckBoxes[checkboxName as CheckBoxKeys]}
                  onChange={handleCheckboxChange}
                  className={`toggle border-slate-500 ${formDataCheckBoxes[checkboxName as CheckBoxKeys] ? 'bg-customPrimary' : 'bg-slate-500'} hover:bg-slate-700`}
                />
              </div>
            );
          })}
        </div>
        <button 
            onClick={handleSubmitVerification}
            className={`w-full py-4 px-5 flex justify-center items-center gap-3
             bg-customPrimary text-white font-bold rounded-md hover:bg-gray-700 duration-500 mt-5 ${loading ? 'cursor-not-allowed bg-slate-300 hover:bg-slate-300' : ''}`}
             disabled={loading}
             >
                {
                    loading ? 
                    (<span className="loading loading-spinner loading-xs"></span>)
                     : (<><span>Submit for verification</span> <MoveRight /></>)
                }
            
            
        </button>
      </div>
    </div>
  )
}

export default VerifyAgentDocuments