import React from 'react';
import tokenService from '../../services/token.service';
import { AdminSideBar, OperationsSideBar, BillingsSideBar } from './';

const SideBar = () => {
  const { user } = tokenService.getUser();
  console.log(user?.email, 'user***'); // Fixed a typo in the console.log

  const renderSideBar = () => {
    if (user) {
      // if (user?.is_operation) {
      //   return <OperationsSideBar />;
      // } else if (user.billing) {
      //   return <BillingsSideBar />;
      // } else {
      //   return <AdminSideBar />;
      // }
      return <AdminSideBar />
    }
    return <div>Loading...</div>;
  };

  return (
    <>
      {renderSideBar()}
    </>
  );
};

export default SideBar;
