import React from 'react';

interface ButtonProps {
  children:React.ReactNode,
  icon?:React.ReactNode | undefined,
  isBorder?: boolean | null,
  // type:string,
  disabled:boolean
  handleClick:any
}

const DefaultButton:React.FC<ButtonProps> = ({ children, icon, isBorder, handleClick, ...props }) => {
  return (
    <button 
    className={`w-full ${isBorder ? 'border border-white bg-transparent hover:bg-customPrimary' 
    : 'bg-customPrimary hover:bg-black/50'} py-4 rounded-md text-white 
    font-bold flex items-center justify-between gap-3  px-7 ease-in duration-500 capitalize text-xsm md:text-sm mt-3`}
    onClick={handleClick}
        {...props}>
        { children }
        <span>{icon}</span>
    </button>
  )
}

export default DefaultButton