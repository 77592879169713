import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FaLongArrowAltRight } from "react-icons/fa";
import { Button } from '../button';
import showingXchangeHeroImage from '../../assets/jpg/successful-business-partners.jpg'
import { DesktopNavigation, MobileNavigationHeader, MobileNavigation } from '../header';
import DynamicPromoBanner from '../marketing/DynamicPromoBanner';




const Hero = () => {
    const [showLiveStreamModal, setShowLiveStreamModal] = useState(false);
    const [showNavItems, setShowNavItems] = useState(false);

    const handleToggleShowNavitems = ()=>{
        setShowNavItems(!showNavItems)
    }
    const handleRedirectGooglePlay = ()=>{
        window.location.href = 'https://play.google.com/store/apps/details?id=com.piiexpo.showingexchangev1';
    }
    const handleRedirectAppleStore = ()=>{
        window.location.href = 'https://apps.apple.com/us/app/showingxchange/id6503052181';
    }

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
    }

    const buttonVariants = {
        hidden: { opacity: 0, x: -70 },
        visible: { opacity: 1, x: 0 },
    };
    const buttonVariantsRight = {
        hidden: { opacity: 0, x: 70 },
        visible: { opacity: 1, x: 0 },
    };

  return (
    <div className='h-screen'>
        <div className='h-full w-full relative'>
            <img src={showingXchangeHeroImage} className='h-full w-full object-cover' />
            {/* <div className='h-full flex absolute w-full top-0'>
                <div className='h-full w-1/2 bg-gray-500 opacity-50'>
                    
                </div>
                <div className='h-full w-1/2 bg-customPrimary opacity-50'>

                </div>
            </div> */}
            <div className='h-full w-full bg-customPrimary opacity-50 absolute top-0 left-0'>
                
            </div>
            <div className='h-full flex flex-col items-cente justify-center absolute w-full top-0  font-primary'>
                <MobileNavigationHeader 
                    handleToggleShowNavitems={handleToggleShowNavitems}
                />
                <div className='md:w-9/12 m-auto text-white text-center'>
                    <h3 className='md:ml-3 font-light font-subPrimary text-sm'>Welcome to</h3>
                    <motion.h1 
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.2 }}
                    className='font-xl font-xbold text-[2.3rem] md:text-[130px] 
                    leading-[3rem] md:leading-[7rem]'>
                        ShowingXchange
                    </motion.h1>
                    <div className='flex flex-col md:flex-row justify-center items-center gap-2 md:gap-5 mt-4 md:ml-2' >
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={buttonVariants}
                            transition={{ duration: 1.2, delay: 0.2 }}
                        >
                            <Button
                                icon={<FaLongArrowAltRight/>}
                                isBorder={true}
                                onClick={handleRedirectAppleStore}
                            >
                                Download The iOS App
                            </Button>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={buttonVariantsRight}
                            transition={{ duration: 1.2, delay: 0.2 }}
                        >
                            <Button
                                icon={<FaLongArrowAltRight />}
                                onClick={handleRedirectGooglePlay}
                            >
                                Download The Android App
                            </Button>
                        </motion.div>
                    </div>
                <DynamicPromoBanner />
                </div>
            </div>
            {/* <div className='absolute top-0 w-full'>
                <DesktopNavigation />
            </div> */}
            {
                showNavItems && (
                    <div className='md:hidden fixed top-0 w-full h-full left-0'>
                    <MobileNavigation
                        showNavItems={showNavItems}
                        handleToggleShowNavitems={handleToggleShowNavitems}
                    />
                    </div>
                )
            }
            
        </div>
    </div>
  )
}

export default Hero