import React, { useState } from 'react';
import { HiDotsVertical } from "react-icons/hi";
import { COLORS } from '../../utils/constants/colors'
import { useNavigate } from 'react-router-dom';
import { useAssignAgent } from '../../hooks/post/useAssignAgent';
import { pageURL } from '../navigation/pagesURL';


interface IAssignedAgentscard {
    handleToggleAssignAgentModal:any,
    application:any,
    i:any,
    setActiveSlug:any
}

const AssignedAgentsCard = ({ handleToggleAssignAgentModal, application, i, setActiveSlug }:IAssignedAgentscard) => {
    const navigateTo = useNavigate();
    // const { assignAgent } = useAssignAgent();

    const handleRedirect = (slug:string)=>{
      navigateTo(pageURL.agentProfile + '/' + slug);
    }

    
  return (
    <>
      <tbody>
        {/* row 1 */}
        <tr>
          <th>
            <label>
              <input type="checkbox" 
              className="checkbox checked:border-[#3EB489 [--chkbg:#808080] [--chkfg:#fff]" />
            </label>
          </th>
          <td>
            <div className="flex items-center gap-3">
              <div className="avatar">
                <div className=" w-12 h-12 border border-slate-400 p-1 rounded-full">
                  <img src={application?.agent_application.upload_picture} alt="showing exchange profile" className='h-full w-full rounded-full' />
                </div>
              </div>
              <div>
                <div className="font-bold">{application?.agent_application?.last_name?.substring(0,10)}</div>
                <div className="text-sm opacity-50">{application?.agent_application?.agent_country}</div>
              </div>
            </div>
          </td>
          <td>
            Zemlak, Daniel and Leannon
            <br/>
            <span className="badge badge-ghost badge-sm">Desktop Support Technician</span>
          </td>
          <td><span className={`badge  badge-sm 
          ${application?.agent_application?.user?.is_application_approved === 'Approved' 
          ? 'bg-customPrimary'
          : application?.agent_application?.user?.is_application_approved === 'Submitted' 
          ? 'bg-yellow-600' : 'badge-ghost'} `}>{application?.agent_application?.user?.is_application_approved}</span></td>
          <th>
            <button className="btn btn-ghost btn-xs" onClick={()=>handleRedirect(application?.agent_application?.slug)}>View Details</button>
          </th>
        </tr>
      </tbody>
      
    </>
  )
}

export default AssignedAgentsCard