import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { FaHome } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { FaGifts } from "react-icons/fa6";
import { MdContactSupport } from "react-icons/md";
import { LiaTimesSolid } from "react-icons/lia";
import { pageURL } from '../navigation/pagesURL';


const MobileNavigation = ({ showNavItems, handleToggleShowNavitems }) => {
   
    const navigateTo = useNavigate();
    const navItems = [
        // {
        //     name:'Home',
        //     url:'/',
        //     icon:<FaHome />
        // },
        {
            name:'Login',
            url:pageURL.signIn,
            // icon:<MdContactSupport />
        },
        {
            name:'About Us',
            url:pageURL.about,
            // icon:<TbListDetails />
        },
        // {
        //     name:'FAQs',
        //     url:pageURL.faq,
         
        // },
        {
            name:'Contact Us',
            url:pageURL.contact,
            // icon:<MdContactSupport />
        },
    ]
  return (
    <div className='h-screen relative'>
        {
            showNavItems && (
                <div className='h-full bg-customPrimary/95 absolute top-0 w-full flex flex-col justify-center items-center gap-5'>
                    <span onClick={handleToggleShowNavitems}><LiaTimesSolid className='text-white rounded-full border p-1' /></span>
                    <ul className='flex flex-col items-center gap-3 text-white font-primary font-bold'>
                        {
                            navItems && navItems.map((item, index)=>(
                                <li 
                                onClick={()=>{
                                    navigateTo(item.url)
                                    handleToggleShowNavitems()
                                }}
                                key={index}>{item.name}</li>
                            ))
                        }
                    </ul>
                </div>
            )
        }
    </div>
  )
}

export default MobileNavigation