import React from 'react';

interface ButtonProps {
  children:React.ReactNode,
  isBorder?: boolean | null,
  bgColor?: string,
  textColor?: string,
  hoverBgColor?: string,
  onClick: () => void;
}

const CustomButton:React.FC<ButtonProps> = ({ children, isBorder, bgColor, hoverBgColor, textColor, ...props }) => {
  return (
    <button 
    className={`w-full ${isBorder ? `border border-white bg-${bgColor} hover:bg-primary` 
    : `bg-${hoverBgColor} hover:bg-black/50`} py-5 rounded-md text-${textColor} 
    font-bold flex items-center justify-center px-7 ease-in duration-500 capitalize mt-3`}
        {...props}>
        { children }
    </button>
  )
}

export default CustomButton