import React, { useState, useEffect } from 'react';
import { Formik, Form } from "formik";
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom';
import { useVerifyAdminToken } from '../../hooks/useVerifyAdminToken';
import { TextInput } from '../../components/forms/inputs';
import { DefaultButton, Button } from '../../components/button';
import { FaLongArrowAltRight, FaCheckCircle } from "react-icons/fa";
import { PasswordChangeFormSchema } from '../../utils/yupSchemas.js';
import { pageURL } from '../../components/navigation/pagesURL';


interface VerifyAdminTokenProps {
    new_password1: string;
    new_password2: string;
}

const ResetOthersPassword = () => {
    const [token, setToken] = useState<string | null>(null);
    const [uid, setUid] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const verifyAdminToken  = useVerifyAdminToken()
    const navigateTo  = useNavigate();

    const handleTogglePassword:any = () => {
        setShowPassword(!showPassword);
      }; 

    const [formData, setFormData] = useState<VerifyAdminTokenProps>({
        new_password1: '',
        new_password2: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    useEffect(() => {
        const getUrlParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const token = queryParams.get('token');
            const uid = queryParams.get('uidb64');

            setToken(token);
            setUid(uid);
        };

        getUrlParams();
    }, []);


    return (
        <div className='h-screen flex flex-col justify-center items-center font-primary'>
            
            {
                isSuccess ? <ResetPasswordConfirm navigateTo={navigateTo} /> : (
                    <div className='shadow-lg p-10 rounded-md mx-3 md:mx-0'>
                        <h3>Kindly create a new password to complete your registration</h3>
                        <div>
                            <Formik
                                initialValues={{ new_password1: "", new_password2: "" }}
                                validationSchema={PasswordChangeFormSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                const { status, data } = await verifyAdminToken({
                                    new_password1: values.new_password1,
                                    new_password2: values.new_password2,
                                    uid: uid || undefined,
                                    token:token || undefined,
                                });
                                console.log(status)
                                console.log(data?.data)
                                if (status === 200) {
                                    const promise = () => new Promise((resolve) => setTimeout(() => resolve({ name: 'Sonner' }), 2000));
                                    toast.promise(promise, {
                                        loading: 'Loading...',
                                        success: (data) => {
                                            return `password reset successful!`;
                                        },
                                        error: 'Error',
                                    });
                                    setIsSuccess(true);
                                }
                                if (status === 404) {
                                toast.error('check your credentials and try again')
                                }
                                setSubmitting(false);
                            }}
                            >
                            {({ isSubmitting }) => (
                                <Form>
                                <div>
                                    <TextInput
                                    label='your password'
                                    name='new_password1'
                                    icon={undefined}
                                    type='password'
                                    placeholder='Your password'
                                    />
                                    <div className='relative'>
                                    <TextInput
                                        label='confirm password'
                                        name='new_password2'
                                        icon={undefined}
                                        
                                        type={showPassword ? "text" : "password"}
                                        placeholder='confirm Your password'
                                        handleTogglePassword={handleTogglePassword}
                                    />
                                    </div>
                                </div>
                                 <Button 
                                    disabled={isSubmitting}
                                    isBorder={false}
                                    >
                                        {isSubmitting ? "Resetting password" : "Reset Password"}
                                </Button>
                                </Form>
                            )}
                            </Formik>
                        </div>
                    </div>
                )
            }
        </div>
    );
};


const ResetPasswordConfirm = ({ navigateTo }:any) => {
    return (
      <div>
          <div className='flex flex-col justify-center items-center gap-3'>
              <FaCheckCircle className='h-12 w-12 text-customSecondary' />
              <h3 className='mt-5 font-normal text-sm text-center'>password reset was successful!</h3>
              <h3 className='mt-5 font-normal text-sm text-center'>proceed to sign in</h3>
              <DefaultButton
                isBorder={false}
                icon={<FaLongArrowAltRight />}
                disabled={false}
                handleClick={()=>navigateTo(pageURL.verifyPhoneNumber)}
            >
                Proceed to Verify Phone Number
            </DefaultButton>
          </div>
      </div>
    )
  }

export default ResetOthersPassword;
