import React from 'react';

interface IDownloadBtn {
  icon: any;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DownloadAppButton: React.FC<IDownloadBtn> = ({ icon, text, onClick }) => {
  return (
    <button
      className='flex items-center justify-center gap-2 py-2 px-3 border 
      rounded-md hover:bg-light duration-300 ease-in bg-white'
      onClick={onClick}
    >
      <span>{icon}</span>
      {text}
    </button>
  );
};

export default DownloadAppButton;
