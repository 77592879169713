import { pageURL } from "../../components/navigation/pagesURL"
import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <div className="hero bg-base-200 min-h-screen font-primary">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold md:text-xl">404</h1>
          <h3 className="text-5xl font-bold md:text-sm">Oops! Page Not Found</h3>
          <p className="py-6">
          The page you're looking for seems to have gone on a little adventure. Don't worry, we'll help you find your way back home.
          </p>
          <Link to={pageURL.signIn}>
            <button className="bg-customPrimary text-white px-10 py-2 rounded-md font-bold">Go Back Home</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound