
import axios, { AxiosResponse } from "axios";
import coreClient from "../../services/coreApi";
import { baseURL } from "../../services/baseURL.service";

interface IPayload {
  slug: string,
}

interface CreateUserResponse {
  status:Number,
  data?:any,
  error?:string
}

type TEndPoint = {
  endpoint:string
}

export const useAssignAgent=()=>{
  const assignAgent = async (payload: IPayload, endpoint:TEndPoint): Promise<CreateUserResponse> => {
    console.log(payload);
    console.log(endpoint)
    try {
      const response: AxiosResponse = await coreClient.post(`${baseURL}/${endpoint.endpoint}/${payload.slug}/`);
      return { status: response.status, data: response.data };
    } catch (error: any) {
      throw error
    }
  };

  return assignAgent;
}