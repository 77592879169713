import React, { useState, useEffect} from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import "leaflet/dist/leaflet.css"
import '../../assets/css/style.css'
import mapIcon from '../../assets/png/map-icon2.png'

const LocationMaps = () => {
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
  const [mapZoom, setMapZoom] = useState(15);

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.center) {
          setMapCenter(data.center);
        }
        if (data.zoom) {
          setMapZoom(data.zoom);
        }
      } catch (error) {
        console.error("Error parsing message data:", error);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const customIcon = new Icon({
    iconUrl:mapIcon,
    iconSize: [50, 50]
  })
  return (
    // <div className='h-screen'>
    //     <MapContainer center={[51.505, -0.09]} zoom={15} scrollWheelZoom={false}>
    //     <TileLayer
    //         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    //     />
    //     <Marker position={[51.505, -0.09]} icon={customIcon}>
    //         <Popup>
    //           <h3>Here is the exact <br />property location.</h3>
    //         </Popup>
    //     </Marker>
    //     </MapContainer>
    // </div>
    <div style={{ height: '100vh' }}>
      <MapContainer center={mapCenter} zoom={mapZoom} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={mapCenter} icon={customIcon}>
          <Popup>
            <h3>Here is the exact <br />property location.</h3>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  )
}

export default LocationMaps