import React, { useState } from 'react';

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    { 
      id: 1, 
      videoSrc: 'https://www.youtube.com/embed/abiu33Sn6wU',
      coverImg: ''
      
    },
    { 
      id: 2, 
      videoSrc: 'https://www.youtube.com/embed/TV5VJ04dP70', 
      coverImg: '' 
    },
    { 
      id: 3, 
      videoSrc: 'https://www.youtube.com/embed/z-WYzE0uQYQ', 
      coverImg: '' 
    },
  ];

  const totalSlides = testimonials.length;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  return (
    <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:px-8 font-primary">
      <h2 className="text-lg font-bold text-center text-gray-900">What Our Clients Say</h2>
      <div className="relative mt-8">
        {/* Carousel Wrapper */}
        <div className="overflow-hidden relative h-80 sm:h-96 md:h-[500px] rounded-lg shadow-lg">
          {/* Carousel Items */}
          <div
            className="h-full flex transition-transform ease-out duration-500"
            style={{ transform: `translateX(-${currentIndex * (100 / 1.25)}%)` }}
          >
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.id}
                className="min-w-[80%] flex-shrink-0 mx-2 shadow-lg"
              >
                <div className="relative h-full w-full bg-gray-100 flex items-center justify-center">
                  <div className="absolute inset-0 flex items-center justify-center z-10">
                    <img
                      src={testimonial.coverImg}
                      alt="Video Thumbnail"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                  <iframe
                    className="w-full h-full object-cover rounded-lg z-20"
                    height={100}
                    src={testimonial.videoSrc}
                    title={`Video ${testimonial.id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Navigation Controls */}
        <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 flex justify-between items-center">
          <button
            onClick={prevSlide}
            className="p-2 ml-4 text-white bg-gray-800 bg-opacity-75 rounded-full hover:bg-gray-600 transition duration-300"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={nextSlide}
            className="p-2 mr-4 text-white bg-gray-800 bg-opacity-75 rounded-full hover:bg-gray-600 transition duration-300"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
