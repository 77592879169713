
import coreClient from '../../services/coreApi';
import { baseURL } from "../../services/baseURL.service";

interface ListAgentsResponse {
  status: number;
  data?: any;
  error?: string;
}

export const useListAgents = () => {
  const listAgents = async (type:string) => {
    try {
      const response = await coreClient.get(`${baseURL}/${type}/`);
      return { status: response.status, data: response.data } as ListAgentsResponse;
    } catch (error) {
      throw error
    }
  };

  return listAgents;
};
