
import axios, { AxiosResponse, AxiosError } from "axios";
import coreClient from "../../services/coreApi";
import { baseURL } from "../../services/baseURL.service";

interface IPayload {
  validated_real_licence_expiry_date: string,
  validated_real_licence_status: string,
  validated_real_licence_address: string,
  validated_real_licence_type: string,
  comments_private: string,
  comments_public: string,
  validated_dl_first_name: string,
  validated_dl_middle_name: string,
  validated_dl_last_name: string,
  validated_dl_number: string,
  validated_profile_dl_pic: boolean,
  is_real_licence_expiry_date_matched: boolean,
  is_real_licence_status_matched: boolean,
  is_real_licence_address_matched:boolean,
  is_dl_first_name_matched:boolean,
  is_dl_last_name_matched: boolean,
  is_dl_number_matched: boolean,
  is_dl_middle_name_matched: boolean,
  is_real_licence_type_matched: boolean,
}

interface IUserID {
  slug: string
}


interface IVerifyAgentDocuments {
  status:Number,
  data?:any,
  error?:string
}

export const useVerifyAgentDocuments=()=>{

  const verifyAgentDocuments = async (payload: IPayload, slug:IUserID  ): Promise<IVerifyAgentDocuments> => {
    console.log(slug, 'slug item')

    const transformedData = Object.fromEntries(
      Object.entries(payload).map(([key, value]) => [
        key,
        typeof value === 'boolean' ? (value ? 'True' : 'False') : value,
      ])
    );
    
    try {
      const response: AxiosResponse = await coreClient.post(
        `${baseURL}/validate-agent-application/${slug}/`,
        transformedData
      );
      return { status: response.status, data: response.data };
    } catch (error: any) {
      let errorResponse: IVerifyAgentDocuments = { status: 500, error: "Unknown error occurred" };

      if (axios.isAxiosError(error) && error.response) {
        errorResponse = {
          status: error.response.status,
          error: error.response.data?.message || "Error occurred",
        };
      }
      return errorResponse;
    }
  };

  return verifyAgentDocuments;
}