import React from 'react';
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { DownloadAppButton } from '../../components/button';

const AppDownload = () => {

  const handleRedirectGooglePlay = ()=>{
    window.location.href = 'https://play.google.com/store/apps/details?id=com.piiexpo.showingexchangev1';
  }
  const handleRedirectAppleStore = ()=>{
      window.location.href = 'https://apps.apple.com/us/app/showingxchange/id6503052181';
  }

  return (
    <div className='md:h-screen flex md:flex-row flex-col justify-center items-center font-primary'>
      <div className='md:w-6/12'>
          
          <div className='text-center my-6'>
              <h3 className='font-bold text-lg leading-10 text-slate-600'>
              <span className='text-customPrimary'>Welcome!</span> <br />
              Download our mobile app for the best experience.
              </h3>
          </div>
      </div>
      <div className='h-full border mx-10 hidden md:block'></div>
      <div className='md:w-4/12'>
          <div className="mockup-phone bg-white">
              <div className="camera"></div> 
              <div className="display">
                  <div className="artboard artboard-demo phone-1 p-4 bg-dark">
                      <div className='w-full flex flex-col justify-center gap-3 my-3'>
                          {/* <h3 className='text-center uppercase font-normal text-white'>Download our mobile app for the best experience</h3> */}
                          <DownloadAppButton icon={<FaGooglePlay />} text='Google Play Store' onClick={handleRedirectGooglePlay} />
                          <DownloadAppButton icon={<FaApple />} text='Apple Store' onClick={handleRedirectAppleStore} />
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default AppDownload