import React from 'react';
import { LineChartHero, DoughnutChart } from '../../../components/graphs';

const StatsGraphs = () => {
  return (
    <div className='flex flex-col md:flex-row gap-x-5 px-3 mt-3'>
        <div className='md:w-2/3 shadow-lg px-3 rounded-md'>
            <LineChartHero />
        </div>
        <div className='md:w-1/2 shadow-lg px-3 rounded-md mt-10 md:mt-0'>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 justify-items-stretch content-stretch">
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Total Number Of Agents</h3>
              <h3 className='text-sm font-normal text-customSecondary'>26</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Number Of Applications</h3>
              <h3 className='text-sm font-normal text-customSecondary'>22</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Total Number Of Agents</h3>
              <h3 className='text-sm font-normal text-customSecondary'>20</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Pending Applications</h3>
              <h3 className='text-sm font-normal text-yellow-500'>3</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Rejected Applications</h3>
              <h3 className='text-sm font-normal text-red-500'>1</h3>
            </div>
            <div className='border p-5 md:p-7 flex flex-col items-center justify-center'>
              <h3 className='text-xxsm font-normal text-center'>Approved Applications</h3>
              <h3 className='text-sm font-normal text-customSecondary'>23</h3>
            </div>
          </div>
        </div>
    </div>
  )
}

export default StatsGraphs