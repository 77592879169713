import React from 'react'
import { IoSearch  } from "react-icons/io5";

const ItemSearch = ({ searchQuery, handleSearchQuery }:{ searchQuery:string, handleSearchQuery:any}) => {
  return (
    <div className='my-5 relative'>
        <input 
            type="text" 
            placeholder='Search agent by first name, last name, country or phone number' 
            className='w-full 
            rounded-md border border-whiteSmoke outline-none py-3 px-10'
            value={searchQuery}
            onChange={handleSearchQuery}
            />
            <span className='absolute left-2 top-3'><IoSearch className='w-6 h-6 text-primary/60' /></span>
    </div>
  )
}

export default ItemSearch