
import axios, { AxiosResponse, AxiosError } from "axios";
import { baseURL } from "../services/baseURL.service";

interface Payload {
    email:string,
}

interface ResetPasswordResponse {
  status:Number,
  data?:any,
  error?:string
}

export const useResetPassword=()=>{
  const resetPassword = async (payload:Payload): Promise<ResetPasswordResponse>=>{
    try {
      const response:AxiosResponse = await axios.post(`${baseURL}/auth/password/reset/`, { email:payload.email });
      return { status:response.status , data: response }
    } catch (error:any) {
      if(axios.isAxiosError(error)){
        const axiosError:AxiosError = error
        return { status: axiosError.response?.status || 500, error:error.message }
      }else{
        return { status: 500, error:'an unknown error occured'}
      }
    }
  }
  return resetPassword
}