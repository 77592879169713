import React from 'react'
import { PageHeader } from '../../components/page-header';
import happyTeam from '../../assets/jpg/happy-team.jpg'
import { DesktopNavigation } from '../../components/header';
import { Footer } from '../../components/footer';


const About = () => {
  return (
    <div>
      <DesktopNavigation />
        <PageHeader
            image={happyTeam}
            label='About Us'
            subLabel='Welcome to ShowingXchange'
      />
      <div className='w-full p-5 md:w-10/12 m-auto md:py-10 font-primary'>
        <div className='mt-4'>
          <p><strong>ShowingXchange</strong> is a revolutionary mobile application designed to transform the way real estate agents manage their property showings. <br /> We understand that in the fast-paced world of real estate, time is of the essence, and juggling multiple tasks can be overwhelming. That's where we come in.</p>
        </div>
        <div className='mt-4'>
        <p>Our app allows busy agents to delegate their property showings to other available agents who are ready to step in and assist. Whether you're swamped with paperwork, stuck in traffic, or handling another client, <strong>ShowingXchange</strong> ensures that your showings are covered by trusted professionals in real-time.</p>
        </div>
        <div className='mt-4'>
          <p>This isn't just about convenience—it's about creating a win-win situation for everyone involved. Clients are thrilled because they get to see properties without delay. The available agents earn extra income by fulfilling these showing requests, and you, as the busy agent, can free up your schedule to focus on what matters most.</p>
        </div>
        <div className='mt-4'>
        <p>At <strong>ShowingXchange</strong>, we believe in empowering agents to work smarter, not harder. With our app, you're not just managing your time better—you're expanding your network, enhancing your service, and boosting your income potential.</p>
        <p>Join the ShowingXchange community today and take your real estate game to the next level.</p>
        </div>
      </div>
    <Footer />
    </div>
  )
}

export default About