import React, { useState, useEffect } from 'react';
import { Formik, Form } from "formik";
import { toast } from 'sonner'
import { useResetPassword } from '../../hooks/useResetPassword';
import { TextInput } from '../../components/forms/inputs';
import { Button } from '../../components/button';
import { FaLongArrowAltRight, FaCheckCircle } from "react-icons/fa";
import ProgressBarTimer from '../../components/ui/ProgressBarTimer';
import { ValidateEmailForPasswordResetSchema } from '../../utils/yupSchemas.js';



const ResetPassword = () => {
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [progressValue, setProgressValue] = useState<number>(0);
    const [email, setEmail] = useState<string>("")
    const resetPassword  = useResetPassword();


    useEffect(() => {
        if (isLoading) {
            const interval = setInterval(() => {
                setProgressValue((prevValue) => {
                    if (prevValue < 100) {
                        return prevValue + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 200); 
            return () => clearInterval(interval);
        }
    }, [isLoading]);


    return (
        <div className='h-screen flex flex-col justify-center items-center font-primary'>
            {
                isSuccess ? (
                    <ResetPasswordConfirm email={email}/>
                ) : (
                    <div className='w-11/12 md:w-5/12 shadow-lg py-10 px-4 md:px-10 md:py-10 rounded-md mx-3 md:mx-0'>
                        <h3 className='font-bold text-sm flex items-center gap-3'>Kindly verify your email 
                        </h3>
                        <div>
                            <Formik
                                initialValues={{ email: ""}}
                                validationSchema={ValidateEmailForPasswordResetSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                setIsLoading(true)
                                const { status, data } = await resetPassword({
                                    email: values.email,
                                });
                                console.log(status)
                                console.log(data?.data)
                                if (status === 200) {
                                setEmail(values.email); 
                                const promise = () => new Promise((resolve) => setTimeout(() => resolve({ name: 'Sonner' }), 2000));
                                toast.promise(promise, {
                                    loading: 'Loading...',
                                    success: (data) => {
                                        return `successfully verified email!`;
                                    },
                                    error: 'Error',
                                });
                                setIsSuccess(true);
                                }
                                if (status === 404) {
                                toast.error('check your credentials and try again')
                                }
                                setSubmitting(false);
                                setIsLoading(false)
                            }}
                            >
                            {({ isSubmitting }) => (
                                <Form>
                                <div>
                                    <TextInput
                                        label='your email'
                                        name='email'
                                        icon={undefined}
                                        type='email'
                                        placeholder='enter your registered email'
                                    />
                                </div>
                                {
                                    isSubmitting ? (<ProgressBarTimer timeCounter={progressValue}/>) :(
                                        <Button 
                                            disabled={isSubmitting}
                                            isBorder={false}
                                            >
                                                {isSubmitting ? "verifying email" : "verify email"}
                                        </Button>
                                    )
                                }
                                </Form>
                            )}
                            </Formik>
                        </div>
                    </div>
                ) 
            }
        </div>
    );
};




const ResetPasswordConfirm = ({ email }: { email: string }) => {
  return (
    <div>
        <div className='flex flex-col justify-center items-center px-3'>
            <FaCheckCircle className='h-12 w-12 text-customSecondary' />
            <h3 className='mt-5 font-normal text-sm text-center'>email check successful!</h3>
            <h3 className='font-normal text-sm text-center'>An email has been sent to 
            <span className=' text-customSecondary font-bold'> {email} </span> 
            Kindly confirm to complete process!
            </h3>
        </div>
    </div>
  )
}



export default ResetPassword;



