import React, { useState, useEffect } from 'react';
import { Formik, Form } from "formik";
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom';
import { useVerifyAdminToken } from '../../hooks/useVerifyAdminToken';
import { CustomTextInput, TextInput } from '../../components/forms/inputs';
import { DefaultButton } from '../../components/button';
import { FaLongArrowAltRight, FaEye, FaEyeSlash } from "react-icons/fa";
import { PasswordChangeFormSchema } from '../../utils/yupSchemas.js';

interface VerifyAdminTokenProps {
    new_password1: string;
    new_password2: string;
}

const ResetAdminPassword = () => {
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [uid, setUid] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const verifyAdminToken  = useVerifyAdminToken()
    const navigateTo  = useNavigate();

    const handleTogglePassword:any = () => {
        setShowPassword(!showPassword);
      }; 

    const [formData, setFormData] = useState<VerifyAdminTokenProps>({
        new_password1: '',
        new_password2: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmitVerificationData = async() => {

        const payload = {
            uid: uid || undefined,
            token:token || undefined,
            new_password1:formData.new_password1,
            new_password2:formData.new_password2
        }
        console.log('Submitting verification data', payload);
        try {
            const { status, data } = await verifyAdminToken(payload);
            console.log(data);
            if(status===200){
                const promise = () => new Promise((resolve) => setTimeout(() => resolve({ name: 'Sonner' }), 2000));
                toast.promise(promise, {
                    loading: 'Loading...',
                    success: (data) => {
                        return `password successfully changed`;
                    },
                    error: 'Error',
                });
                navigateTo('/xchange/verify-phone-number')
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        const getUrlParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const token = queryParams.get('token');
            const uid = queryParams.get('uidb64');
            const phone = queryParams.get('phone');

            setToken(token);
            setUid(uid);
            setPhoneNumber(phone);
        };

        getUrlParams();
    }, []);

    const isFormValid = formData.new_password1 !== '' && formData.new_password1 === formData.new_password2;

    return (
        <div className='h-screen flex flex-col justify-center items-center font-primary'>
            <div className='shadow-lg p-10 rounded-md mx-3 md:mx-0'>
                <h3>Kindly create a new password to complete your registration</h3>
                <div>
                    {/* <CustomTextInput
                        label='Enter your new password'
                        handleChange={handleChange}
                        inputAttrs={{
                            type: 'password',
                            name: 'new_password1',
                            placeholder: 'Password',
                            value:formData.new_password1
                        }}
                    />
                    <CustomTextInput
                        label='Confirm password'
                        handleChange={handleChange}
                        inputAttrs={{
                            type: 'password',
                            name: 'new_password2',
                            placeholder: 'Confirm Password',
                            value:formData?.new_password2
                        }}
                    />
                    <div className='mt-5'>
                        <DefaultButton
                            isBorder={false}
                            icon={<FaLongArrowAltRight />}
                            disabled={!isFormValid}
                            handleClick={handleSubmitVerificationData}
                        >
                            Change Password
                        </DefaultButton>
                    </div> */}
                    <Formik
                  initialValues={{ new_password1: "", new_password2: "" }}
                  validationSchema={PasswordChangeFormSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                        setLoading(true);
                        const { status, data } = await verifyAdminToken({
                            new_password1: values.new_password1,
                            new_password2: values.new_password2,
                            uid: uid || undefined,
                            token:token || undefined,
                        });
                        console.log(status)
                        console.log(data?.data)
                        if (status === 200) {
                            const promise = () => new Promise((resolve) => setTimeout(() => resolve({ name: 'Sonner' }), 2000));
                            toast.promise(promise, {
                                loading: 'Loading...',
                                success: (data) => {
                                    return `successfully logged in!`;
                                },
                                error: 'Error',
                            });
                            navigateTo('/xchange/verify-phone-number');
                        }
                    } catch (error:any) {
                        if (error.response && error.response.data && error.response.data) {
                            toast.error(`${error.response.data.message}`);
                          } else if (error.request) {
                              toast.error('No response from server. Please try again later.');
                          } else {
                              toast.error(`${error.message}`);
                          }
                    }
                    setSubmitting(false);
                    setLoading(false);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div>
                        <TextInput
                          label='your password'
                          name='new_password1'
                          icon={undefined}
                          
                          // autoComplete='email'
                          type='password'
                          placeholder='Your password'
                        />
                        <div className='relative'>
                          <TextInput
                            label='confirm password'
                            name='new_password2'
                            icon={undefined}
                            
                            type={showPassword ? "text" : "password"}
                            placeholder='confirm Your password'
                            handleTogglePassword={handleTogglePassword}
                          />
                        </div>
                      </div>
                      {/* <DefaultButton
                            isBorder={false}
                            icon={<FaLongArrowAltRight />}
                            disabled={!isFormValid}
                            handleClick={handleSubmitVerificationData}
                        >
                            Change Password
                    </DefaultButton> */}
                    <button className={`w-full bg-customPrimary px-7 py-4 text-center text-white rounded-md ${isSubmitting ? 'cursor-not-allowed bg-[#e3e3e3]' : ''}`} 
                    disabled={isSubmitting}>
                        {loading ? 'Updating Password' : 'Change Password'}
                    </button>
                    </Form>
                  )}
                </Formik>
                </div>
            </div>
        </div>
    );
};

export default ResetAdminPassword;
