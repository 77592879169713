import React, { useState, useEffect, Suspense } from 'react';
import { Formik, Form } from "formik";
import { toast } from 'sonner';
import { FaLongArrowAltRight } from 'react-icons/fa'
import { StatsCounter, StatsGraphs } from './stats';
import DemoPage from '../../components/payments/CustomTable';
import { ExchangeDataTable } from './tables';
import { AgentApplicationListCard } from '../../components/cards';
import { useListAgents } from '../../hooks/get/useListAgents';
import { useAssignAgent } from '../../hooks/post/useAssignAgent';
import { useCreateUser } from '../../hooks/post/useCreateUser';
import { PlaceholderCard } from '../../components/skeleton';
import { ModalDialog } from '../../components/modal';
import { TextInput } from '../../components/forms/inputs';
import { Button } from '../../components/button';
import { DashboardHeader } from './header';
import { useModalContext } from '../../contexts/modalContext';
// import { CreateOperationOrBillingFormSchema } from '../../utils/yupSchemas';
import { countriesFlagPhoneExts } from '../../utils/countriesFlagPhoneExts';
import { PhoneInput } from '../../components/forms/inputs';
import coreClient from '../../services/coreApi';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/SelectFilter"
import { ItemSearch } from '../../components/search';
import { SimpleSpinner } from '../../components/loaders';




const AdminDashboard = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchCountryQuery, setCountrySearchQuery] = useState<string>('');
  const [agents, setAgents] = useState([]);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [loadingAssignAgent, setLoadingAssignAgent] = useState(false);
  const [showAssignAgentModal, setShowAssignAgentModal] = useState(false);
  const [activeSlug, setActiveSlug] = useState<string>('');
  const listAgents = useListAgents();
  const assignAgent = useAssignAgent();
 


    const handleToggleAssignAgentModal = ()=>{
      setShowAssignAgentModal(!showAssignAgentModal);
    }

    const handleAssignAgent = async()=>{
      try {
        setLoadingAssignAgent(true);
        //const { status, data } = await coreClient.post(`${process.env.REACT_APP_API_URL}/assign-agent-application/${activeSlug}/`);
        const { status, data } = await assignAgent({ slug:activeSlug }, { endpoint:'assign-agent-application' });
        if (status === 200) {
          toast.success(`${data.message}`);
          handleToggleAssignAgentModal();
          renderAgentApplications();
        } 
      } catch (error:any) {
        if (error.response && error.response.data && error.response.data) {
          toast.error(`${error.response.data.message}`);
        } else if (error.request) {
            toast.error('No response from server. Please try again later.');
        } else {
            toast.error(`${error.message}`);
        }
      }finally{
        setLoadingAssignAgent(false)
      }
    }

    const handleSearchQuery = (e:any)=>{
        setSearchQuery(e.target.value);
    }

    const filterdAgents = agents && agents.filter((agent:any)=>{
        const result = `${agent?.first_name} ${agent?.gender} ${agent?.last_name} ${agent?.agent_country}`;
        return result.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) 
    })

    // console.log(agents && agents, 'agents')
    // console.log(filterdAgents && filterdAgents, 'filterdAgents')

  //   const filterdCountries = countriesFlagPhoneExts && countriesFlagPhoneExts.filter((country)=>{
  //     const result = `${country?.name} ${country?.dial_code}`;
  //     return result.toLocaleLowerCase().includes(searchCountryQuery.toLocaleLowerCase()) 
  // })

  async function renderAgentApplications(){
      try {
        const { status, data  } = await listAgents('list-agent-applications');
        if(status===200){
          setAgents(data?.data)
        }
      } catch (error:any) {
        if (error.response && error.response.data && error.response.data) {
          toast.error(`${error.response.data.message}`);
        } else if (error.request) {
            toast.error('No response from server. Please try again later.');
        } else {
            toast.error(`${error.message}`);
        }
      }
  }

    useEffect(()=>{
      setTimeout(async()=>{
        try {
          setLoadingAgent(true);
          const { status, data  } = await listAgents('list-agent-applications');
          if(status===200){
            setAgents(data?.data)
          }
        } catch (error:any) {
          if (error.response && error.response.data && error.response.data) {
            toast.error(`${error.response.data.message}`);
          } else if (error.request) {
              toast.error('No response from server. Please try again later.');
          } else {
              toast.error(`${error.message}`);
          }
        }finally{
          setLoadingAgent(false)
        }
      },2000);
    },[]);

    


  return (
    <div className='font-primary'>
      <DashboardHeader />
      <StatsGraphs />
      <div className='bg-white rounded-md shadow-lg mx-3 p-5 mt-5'>
        <div className='flex items-center justify-between border-b pb-3'>
          <h3 className='font-bold text-xsm'>Agent Applications ({agents && agents.length})</h3>
          <div>
            <Select>
              <SelectTrigger className="md:w-[180px]">
                <SelectValue placeholder="All" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="light">Approved</SelectItem>
                <SelectItem value="system">Pending</SelectItem>
                <SelectItem value="dark">Rejected</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <ItemSearch
          searchQuery={searchQuery}
          handleSearchQuery={handleSearchQuery}
        />
        <div className='h-72 my-5 overflow-y-auto'>
          {
            loadingAgent ? (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                {Array.from({ length: 8}).map((_, i) => (
                  <PlaceholderCard key={i} />
                ))}
              </div>
            ) : (
              <div className='overflow-y-auto'>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>
                        {/* <label>
                          <input type="checkbox" className="checkbox" />
                        </label> */}
                      </th>
                      <th>Name</th>
                      <th>Job</th>
                      <th>Status</th>
                      <th>Details</th>
                      <th>Assign Agent</th>
                      <th></th>
                    </tr>
                  </thead>
                  {
                    filterdAgents && filterdAgents.map((application, i)=>(
                      <AgentApplicationListCard 
                        handleToggleAssignAgentModal={handleToggleAssignAgentModal}
                        setActiveSlug={setActiveSlug}
                        application={application} 
                        i={i} 
                        key={i}
                      />
                    ))
                  }
                </table>
              </div>
            )
          }
        </div>
      </div>
      {/* <ModalDialog
        modalOpen={openModal}
        modalClose={close}
      >
        <h3 className='font-bold text-sm py-2 border-b'>create new user</h3>
        <Formik
            initialValues={{ email: "", phone_number:"", password: "", }}
            validationSchema={CreateOperationOrBillingFormSchema}
         
            onSubmit={async (values, { setSubmitting }) => {
              const operationsUserPayload = {
                email: values.email,
                password: values.password,
                phone_number: values.phone_number,
                country_code: selectedCountry.dial_code,
                is_operation: true
              }
              const billingsUserPayload = {
                email: values.email,
                password: values.password,
                phone_number: values.phone_number,
                country_code: selectedCountry.dial_code,
                is_billing: true
              }
              try {
                const { status, data, error } = await createUser(selectedUserType === 'operation' ? operationsUserPayload : billingsUserPayload);
                console.log(status)
                console.log(data)
                if (status === 201) {
                  toast.success(data?.message);
                  close();
                }
                if(status !==201){
                  toast.error(error)
                }
              } catch (error:any) {
                return error
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
              <div>
                <TextInput
                  label='user email'
                  name='email'
                  icon={undefined}
                  type='email'
                  placeholder='email'
                />
                <>
                <h3 className='text-sm font-normal mb-1 ml-1'>Enter User Phone Number</h3>
                  <div className='flex items-center relative'>
                    <div className='md:w-3/12'>
                        <div className='p-3 flex items-center gap-3 border cursor-pointer text-sm' onClick={handleToggleCountrySearch}>
                            <h3>{selectedCountry.flag}</h3>
                            <span>{selectedCountry.dial_code}</span>
                        </div>
                        <div className=''>
                            {
                                showCountrySearch && (
                                    <div className='w-full h-64 p-5 shadow-lg mt-10  absolute top-10 left-0 bg-white z-10 overflow-y-scroll'>
                                        <input 
                                            type="search"
                                            className='w-full rounded-md px-5 py-3 mt-2 outline-none border'
                                            placeholder='search country code'
                                            value={searchCountryQuery}
                                            onChange={handleCountrySearchQuery}
                                        />
                                        <ul className=''>
                                            {
                                                filterdCountries.map((country, i)=>(
                                                    <li className='flex items-center gap-3 border-b py-2 cursor-pointer' key={i} onClick={()=>{
                                                        handleSelectCountry(country)
                                                        handleToggleCountrySearch()
                                                    }}>
                                                        <span>{country.flag}</span><h3>{country.name}({country.dial_code})</h3>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                     <PhoneInput
                      name='phone_number'
                      icon={undefined}
                      type='number'
                      placeholder='user phone number'
                    />
                  </div>
                </>
                <div className='relative'>
                  <TextInput
                    label='user password'
                    name='password'
                    icon={undefined}
                    type='password'
                    placeholder='password'
                  />
                </div>
                <div className="w-full dropdown border rounded-md text-sm">
                  <select className="select select-ghost w-full" onChange={handleChange} value={selectedUserType}>
                    <option disabled value={''} className='w-full'>Select type of account</option>
                    {userTypes.map((option, i) => (
                        <option key={i} value={option}>
                          {option}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {
                isSubmitting ? 
                <div className='flex justify-center items-center py-5'><SimpleSpinner size={'lg'}/></div> :
                (
                  <Button 
                  // type='submit' 
                  disabled={isSubmitting}
                  isBorder={false}
                  >
                    {isSubmitting ? "creating your account" : "Create " +  selectedUserType + ' user account'}
                  </Button>
                )
              }
            </Form>
            )}
          </Formik>
      </ModalDialog> */}
      {
        showAssignAgentModal && (
          <div className="h-full w-full flex justify-center items-center bg-white/60 absolute top-0 left-0 ">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Assign Agent</h3>
              <p className="py-4">Are you sure you want to assign this agent to yourself?</p>
              <div className="w-full modal-action flex items-center gap-5">
                <button className="bg-customPrimary px-10 py-3 rounded-sm font-normal text-white" onClick={handleAssignAgent}>
                  { loadingAssignAgent ? <SimpleSpinner size={'sm'}/> : 'Yes' }
                </button>
                <button className="bg-whiteSmoke px-10 py-3 rounded-sm font-normal" onClick={handleToggleAssignAgentModal}>Close</button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default AdminDashboard