import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import CountDownTimerB from '../timer/CountDownTimerB';
import { pageURL } from '../navigation/pagesURL';

const DynamicPromoBanner = () => {
  const navigateTo = useNavigate();
  const [showBanner, setShowBanner] = useState(true);

  const handleRedirect = () => {
    navigateTo(pageURL.registration);
  };

  const initialPromoStartDate = '2024-09-02T00:00:00'

  useEffect(() => {
    const promoStartDate = new Date(initialPromoStartDate); 
    const now = new Date();
    const differenceInTime = now.getTime() - promoStartDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays > 3) {
      setShowBanner(false); 
    }
  }, []);

  function getDateThreeDaysFromPromo(promoStartDateStr:string) {

    const promoStartDate = new Date(promoStartDateStr);
  
    const threeDaysLater = new Date(promoStartDate.getTime() + 3 * 24 * 60 * 60 * 1000);
    const year = threeDaysLater.getFullYear();
    const month = String(threeDaysLater.getMonth() + 1).padStart(2, '0'); 
    const day = String(threeDaysLater.getDate()).padStart(2, '0');
    const hours = String(threeDaysLater.getHours()).padStart(2, '0');
    const minutes = String(threeDaysLater.getMinutes()).padStart(2, '0');
    const seconds = String(threeDaysLater.getSeconds()).padStart(2, '0');
  
 
    return `${month}-${day}-${year}`;
  }
  
  const threeDaysLaterDate = getDateThreeDaysFromPromo(initialPromoStartDate);

  

  if (!showBanner) return null; 

  return (
    <div className='flex flex-col md:flex-row justify-center items-center gap-3 mt-10 mx-2 md:mx-0'>
      <div className='md:w-9/12 bg-white px-2'>
        <div className='py-5'>
          <p className='text-dark-tremor-brand-faint text-sm'>
          For a limited time, enjoy <span className='capitalize font-bold'> 60% </span> off the one-time sign-up application processing fee with promo code
            <span className='uppercase font-bold'> newsignup </span> 
            Hurry,  
            this offer expires in just three days on <b> {threeDaysLaterDate}</b>.
          </p>
        </div>
        {/* <button 
          className='w-full bg-customPrimary py-5 font-bold flex items-center justify-center px-7 ease-in duration-500 capitalize mt-3'
          onClick={handleRedirect}
        >
          Create Account
        </button> */}
      </div>
      {/* <CountDownTimerB /> */}
    </div>
  );
};

export default DynamicPromoBanner;
