import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TiUserAdd } from "react-icons/ti";
import { FiUsers } from "react-icons/fi";
import { RiCoupon2Line } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import { DashboardIcon,  } from '@radix-ui/react-icons';
import { AiOutlineLogout } from "react-icons/ai";
import { FaWpforms } from "react-icons/fa";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { GrUserAdmin } from "react-icons/gr";
import { FaPlus } from "react-icons/fa6";
import tokenService from '../../services/token.service';
import { useModalContext } from '../../contexts/modalContext';
import { pageURL } from '../navigation/pagesURL';
import logo from '../../assets/png/showingxchange-logo.png';


interface SideBarItem {
  name: string;
  icon: React.ReactNode;
  link: string;
}

const AdminSideBar: React.FC = () => {
  const [selectedLInk, setSelectedLink] = useState(null);
  const { open } = useModalContext();
  const sideBarNavItems: SideBarItem[] = [
    {
      name: 'Dashboard',
      icon: <DashboardIcon className='w-6 h-6' />,
      link: pageURL.adminDashboard,
    },
    {
      name: 'Operations',
      icon: <GrUserAdmin className='w-6 h-6' />,
      link: pageURL.listOperations,
    },
    {
      name: 'Assigned Applications',
      icon: <GrUserAdmin className='w-6 h-6' />,
      link: pageURL.listAssignedAgents,
    },
    {
      name: 'Billings',
      icon: <TiUserAdd className='w-6 h-6' />,
      link: pageURL.listBillings,
    },
    {
      name: 'Agent Applications',
      icon: <FaWpforms className='w-6 h-6' />,
      link: '/agent-applications-page',
    },
    {
      name: 'Coupons',
      icon: <RiCoupon2Line className='w-6 h-6' />,
      link: '/coupons-page',
    },
    {
      name: 'Disputes',
      icon: <TiUserAdd className='w-6 h-6' />,
      link: '/disputes-page',
    },
    {
      name: 'Settings',
      icon: <IoSettingsOutline className='w-6 h-6' />,
      link: '/settings-page',
    },
  ];

  const handleSelectedLink = (link:any)=>{
    setSelectedLink(link)
  }

 
  const navigateTo = useNavigate();

  const handleLogOut = ()=>{
    tokenService.removeUser()
    navigateTo('/')
  }

  const handleRedirect = (url:string)=>[
    navigateTo(url)
  ]

  return (
    <div className='h-full flex flex-col text-xsm font-primary font-normal justify-between'>
      <div className='border border-b '>
        <div className='w-10/12 m-auto flex items-center gap-3 p-3'>
          <img src={logo} alt="showingxchange-logo" className='rounded-full h-12 w-12' />
          <div>
            <h3 className='text-xsm font-bold text-customPrimary'>showingxchange</h3>
            <h3 className='text-xxsm font-bold text-customPrimary'>Dashboard</h3>
          </div>
        </div>
      </div>
      <div className='h-full flex flex-col'>
        <ul className='w-10/12 m-auto'>
        {/* <button 
        onClick={()=>open()}
        className='w-full flex items-center justify-between 
        gap-3 bg-customPrimary rounded-sm py-2 px-3 font-normal 
        text-white text-sm mb-3 hover:bg-customSecondary ease-in duration-300 '>create user <FaPlus className='text-white' /></button> */}
          {sideBarNavItems.map((item, index) => (
            <li key={index} 
            className={`${selectedLInk===item.name ? 'bg-whiteSmoke' : ''} hover:bg-whiteSmoke py-1 px-2 my-1 rounded-md cursor-pointer`} onClick={()=>handleSelectedLink(item.name)}>
              <h3 
              onClick={()=>handleRedirect(item.link)}
              className='flex items-center gap-3 my-1'>
                <span className='text-customSecondary font-bold'>{item.icon}</span>
                {item.name}
              </h3>
            </li>
          ))}
        </ul>
      </div>
      <div className='border border-t p-10'>
        <div className='w-11/12 m-auto flex items-center gap-3'>
          <span className='text-customSecondary font-bold'><AiOutlineLogout /></span>
          <span onClick={handleLogOut} className='cursor-pointer'>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
