import React, { useState, useEffect } from 'react';
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom';
import { useVerifyOtp } from '../../hooks/useVerifyOtp';
import { useResendOtp } from '../../hooks/useResendOtp';
import { CustomTextInput } from '../../components/forms/inputs';
import { DefaultButton } from '../../components/button';
import { FaLongArrowAltRight } from "react-icons/fa";
import { SimpleSpinner } from '../../components/loaders';

interface VerifyAdminPhoneProps {
    phone_number: string;
}

const VerifyAdminPhoneNumber = () => {
    const [loading, setLoading] = useState(false);
    const verifyOtp  = useVerifyOtp();
    const resendOtp  = useResendOtp();
    const navigateTo  = useNavigate();

    const [formData, setFormData] = useState<VerifyAdminPhoneProps>({
        phone_number: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmitVerificationData = async() => {
        // console.log('Submitting verification data', payload);
        const payload = {
            phone_number:formData.phone_number
        }
        try {
            setLoading(true)
            const { status, data } = await resendOtp(payload);
            console.log(data);
            if(status===200){
                const promise = () => new Promise((resolve) => setTimeout(() => resolve({ name: 'Sonner' }), 2000));
                toast.promise(promise, {
                    loading: 'Loading...',
                    success: (data) => {
                        return `successful, proceed to verify phone number`;
                    },
                    error: 'Error',
                });
                navigateTo('/xchange/verify-otp')
            }
        } catch (error:any) {
            if (error.response && error.response.data && error.response.data) {
                toast.error(`${error.response.data.message}`);
              } else if (error.request) {
                  toast.error('No response from server. Please try again later.');
              } else {
                  toast.error(`${error.message}`);
              }
        }finally{
            setLoading(false);
        }

        
        
    };

  

    const isFormValid = formData.phone_number !== '' || formData.phone_number.length < 10;

    return (
        <div className='h-screen flex flex-col justify-center items-center font-primary'>
            <div className='shadow-lg p-10 rounded-md mx-3 md:mx-0'>
                <h3>Kindly verify your phone number </h3>
                <div>
                    <CustomTextInput
                        label='Enter Phone Number'
                        handleChange={handleChange}
                        inputAttrs={{
                            type: 'text',
                            name: 'phone_number',
                            placeholder: 'phone number',
                            value:formData.phone_number
                        }}
                    />
                    <div className='mt-5'>
                        <DefaultButton
                            isBorder={false}
                            icon={<FaLongArrowAltRight />}
                            disabled={!isFormValid}
                            handleClick={handleSubmitVerificationData}
                        >
                            
                            {
                                loading ? <SimpleSpinner size='xs' /> : <span>submit phone number</span>
                            }
                        </DefaultButton>
                    </div>
                    {/* <div className='py-5'>
                        <Text className=''>Didn’t receive any code?
                            <TouchableOpacity onPress={handleResendOtp}>
                            <Text className='border-b text-primary translate-y-1'> Resend OTP Again</Text>
                            </TouchableOpacity> 
                        </Text>
                        </View>
                        <View className=''>
                        <Text className=''>Request new code in 00:30s </Text>
                        </View>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default VerifyAdminPhoneNumber;
