import { LineChart } from '@tremor/react';

const chartdata = [
  {
    date: 'Jan 22',
    GeneralAnalysis: 2890,
    AgentAnalysis:3600,
    'platform users': 2338,
  },
  {
    date: 'Feb 22',
    GeneralAnalysis: 2756,
    AgentAnalysis:2500,
    'platform users': 2103,
  },
  {
    date: 'Mar 22',
    GeneralAnalysis: 3322,
    AgentAnalysis:3000,
    'platform users': 2194,
  },
  {
    date: 'Apr 22',
    GeneralAnalysis: 3470,
    AgentAnalysis:2500,
    'platform users': 2108,
  },
  {
    date: 'May 22',
    GeneralAnalysis: 3475,
    AgentAnalysis:3012,
    'platform users': 1812,
  },
  {
    date: 'Jun 22',
    GeneralAnalysis: 3129,
    AgentAnalysis:3001,
    'platform users': 1726,
  },
  {
    date: 'Jul 22',
    GeneralAnalysis: 3490,
    AgentAnalysis:190,
    'platform users': 1982,
  },
  {
    date: 'Aug 22',
    GeneralAnalysis: 2903,
    AgentAnalysis:2800,
    'platform users': 2012,
  },
  {
    date: 'Sep 22',
    GeneralAnalysis: 2643,
    AgentAnalysis:2600,
    'platform users': 2342,
  },
  {
    date: 'Oct 22',
    GeneralAnalysis: 2837,
    'platform users': 2473,
  },
  {
    date: 'Nov 22',
    GeneralAnalysis: 2954,
    AgentAnalysis:3900,
    'platform users': 3848,
  },
  {
    date: 'Dec 22',
    GeneralAnalysis: 3239,
    AgentAnalysis:2500,
    'platform users': 3736,
  },
];

const dataFormatter = (number:number) =>
  `$${Intl.NumberFormat('us').format(number).toString()}`;

export  default function LineChartHero() {
  return (
    <LineChart
      className="h-64 text-xxsm"
      data={chartdata}
      index="date"
      categories={['GeneralAnalysis', 'platform users', 'AgentAnalysis']}
      colors={['indigo', 'rose', 'yellow']}
      valueFormatter={dataFormatter}
      yAxisWidth={60}
      onValueChange={(v) => console.log(v)}
    />
  );
}